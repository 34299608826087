// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_profile-page__zbSh0 {
  padding: 2% 5%;
}
.styles_profile-page_user-info__X0aa8 {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 834px) {
  .styles_profile-page_user-info__X0aa8 {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/components/Profile/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;AAEJ;;AAEA;EACE;IACE,cAAA;EACF;AACF","sourcesContent":[".profile-page {\n  padding: 2% 5%;\n  &_user-info{\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n@media (max-width: 834px) {\n  .profile-page_user-info{\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-page": `styles_profile-page__zbSh0`,
	"profile-page_user-info": `styles_profile-page_user-info__X0aa8`
};
export default ___CSS_LOADER_EXPORT___;
