import { ChangeEvent, useEffect, useState } from 'react';
import styles from '../Auth.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { loginUser, registerUser } from "../../api/auth.api";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Currency } from '../models';
import { Loading } from '../../loading/Loading';

function Login() {
    const [email, setEmail] = useState("");
    const [user, setUser] = useState<any>(null);
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const login = async (em: string, pass: string) => {
        setLoading(true);
        try {
            const { access_token } = await loginUser(em, pass);
            localStorage.setItem('token', access_token);
            navigate('/main/tradeList');
        } catch (error) {
            console.error(error);
            setShowError(true);
        } finally {
            setLoading(false)
        }
    };

    const loginByGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse as any),
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleRegisterAndLogin = async (us: any) => {
        setLoading(true);
        if (!us.email || !us.id) {
            console.error("Email or ID is missing from Google OAuth response.");
            setShowError(true);
            setLoading(false);
            return;
        }

        try {
            await registerUser(us.email, us.id, Currency.USD);
            await login(us.email, us.id);
            navigate('/main/profile');
        } catch (error) {
            console.error('Login failed:', error);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            const accessToken = user.access_token;
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log("RES", res)
                    handleRegisterAndLogin(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [user]);

    return (
        <main className={styles.background}>
            {loading && (
                <Loading />
            )}
            <div className={`${styles.block} ${styles.loginBlock}`}>
                <section className={styles['block-main']}>
                    <header className={styles['block-main-header']}>
                        <h1>Вход</h1>
                        <h3 className={styles['block-main-header-title']}>Инвестируй. Торгуй. Побеждай</h3>
                    </header>

                    <div className={styles['block-main-inputs']}>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="mail" className={styles['block-main-inputs-details_label']}><MailOutlined /></label>
                            <input type="email" id="mail" placeholder="Почта"
                                className={styles['block-main-inputs-details_input']} value={email}
                                onChange={handleEmailChange} />
                        </div>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="password"
                                className={styles['block-main-inputs-details_label']}><LockOutlined /></label>
                            <input type="password" id="password" placeholder="Пароль"
                                className={styles['block-main-inputs-details_input']} value={password}
                                onChange={handlePasswordChange} />
                        </div>
                    </div>
                    <div className={styles.errorContainer}>
                        {showError && <div className={styles.error}>Неверные данные</div>}
                    </div>
                    <div className={styles['block-main-confirm']}>
                        <Checkbox className={styles['block-main-checkbox']}><label>Запомнить меня</label></Checkbox>
                        <Link to="/auth/password-recovery" className={styles.link}>Забыл пароль</Link>
                    </div>
                    <div className={styles['block-main-buttons']}>
                        <button
                            type="submit"
                            className={`${styles['button-light']} ${styles.button}`}
                            onClick={() => login(email, password)}
                            disabled={loading}
                        >
                            Войти
                        </button>
                        <button
                            type="submit"
                            className={`${styles['button-transparent']} ${styles.button}`}
                            onClick={() => loginByGoogle()}
                            disabled={loading}
                        >
                            Войти через Google
                        </button>
                    </div>
                    <div className={styles['block-main-state']}>
                        <span>Еще нет аккаунта? </span>
                        <Link to='/auth/registration' className={styles.link}>Регистрация</Link>
                    </div>
                </section>

                <img src={require('../../assets/logo.png')} className={styles.logo} alt="logo" />
            </div>
        </main>
    );
}

export default Login;
