import React from "react";
import HelperItem from "./HelperItem/HelperItem";
import styles from "./Helper.module.scss";
import { Header } from "../Header/Header";

function Helper() {
    const questionAnswer: Array<{ header: string; description: string }> = [
        {
            header: 'Как научиться ?\nЭто сложно ?',
            description: 'Зарегистрируйтесь и тренируйтесь на демо-счете. Это тоже самое, что и реальная торговля, но бесплатно. На самом деле это не сложно.'
        },
        {
            header: 'Сколько времени занимает\nвывод средств ?',
            description: 'В среднем процедура вывода средств занимает от одного до пяти дней с даты получения соответствующей заявки Клиента и зависит только от объема одновременно обрабатываемых заявок. Компания всегда старается производить выплаты непосредственно в день поступления запроса от Клиента.',
        },
        {
            header: 'Какая минимальная сумма пополнения счета ?',
            description: 'Преимущество торговой платформы компании в том, что вам не нужно вносить большие суммы на свой счет. Вы можете начать торговать, вложив небольшую сумму денег. Минимальный депозит 500 рублей .',
        },
        {
            header: 'Имеется ли комиссия при пополнении или выводе средств со счета ?',
            description: 'Нет. Компания не взымает комиссию ни за операцию пополнения счета, ни за вывод средств.\nОднако, стоит учитывать, что платежные системы могут взимать свои комиссии и использовать внутренний курс конвертации валют.',
        },
    ];

    return (
        <div>
            <Header />
            <div className={styles["helper-container"]}>
                {questionAnswer.map((item, index) => (
                    <HelperItem key={index} header={item.header} description={item.description} />
                ))}
            </div>
        </div>
    );
}

export default Helper;
