export enum PaymentOptionEnum {
    'MasterCard', 'FK-wallet', 'Ю money', "BIT COIN", 'USDT trc-20', 'VISA'
}

export interface Payment {
    id: number;
    is_refill: boolean,
    uid: string;
    user_id: number;
    date_of_transaction: string;
    amount: number;
}

export enum PaymentActionEnum {
    "Withdraw", "Refill"
}

export const Promo = [
    { sum: 50, bonus: 10 },
    { sum: 100, bonus: 20 },
    { sum: 200, bonus: 30 },
    { sum: 500, bonus: 40 },
]

export interface Balance {
    balance: number,
    demo_balance: number,
    freezed: number
}

export interface UserStats {
    monthly_profit: number,
    successful_orders: number,
    failed_orders: number,
    total_turnover: number,
    success_rate: number
}

export interface UserPrediction {
    id: number,
    amount: number,
    is_up: boolean,
    time_of_open: string,
    dot_of_open: number,
    dof_of_close: number,
    symbol: string,
    prediction_result: number,
    sum_of_win: number,
    user_id: number
}

export interface UserPosition {
    username: string,
    sum: string,
    winsCount: number,
    averageProfit: number,
    minBid: number,
    maxBid: number,
    rank: string
}

export interface Profile {
    uid: string;
    email: string;
    rank: number,
    profile: {
        nickname: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        date_of_birth: string;
        currency: string;
        image: string;
    };
}

export type CurrencyCode = 'USD' | 'EUR' | 'RUB';

export const currencySymbols: Record<'USD' | 'EUR' | 'RUB', string> = {
    USD: '$',
    EUR: '€',
    RUB: '₽'
};

export interface LeaderboardUser {
    user_id: number,
    flag: string,
    image: string,
    total_earned_today: number,
    total_wins_today: number,
    average_profit_today: number,
    min_bet_today: number,
    max_bet_today: number
}