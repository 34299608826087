import { useEffect, useState } from "react"
import { Header } from "../Header/Header"
import styles from "./historyTransactions.module.scss"
import { useUser } from "../../useUser";
import { Payment } from "../../models";
import { formatDate, formatNumber, getCurrencySymbol, getTransitionsHistory } from "../../../api/auth.api";

export const HistoryTransaction = () => {
    const user = useUser();
    const [refills, setRefills] = useState<Payment[]>([]);
    const [deducts, setDeducts] = useState<Payment[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const data = await getTransitionsHistory();
                setPayments(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchHistory();
        const sortedRefills = payments.filter(payment => payment.is_refill);
        const sortedDeducts = payments.filter(payment => !payment.is_refill);

        setRefills(sortedRefills);
        setDeducts(sortedDeducts);
    }, []);

    return <div>
        <Header />
        <h4 className={styles.title}>История транзакций</h4>
        <div className={styles.paymentsTables}>
            <div className={styles["payments-block"]}>
                <h3 className={styles["payments-header"]}>Пополнения</h3>
                <div className={styles["payments-table"]}>
                    {refills.map((item, index) => (
                        <div
                            className={styles["payments-table-item"]}
                            key={index}
                        >
                            <div className={styles["payments-table-item-property"]}>#{item.id}</div>
                            <div className={styles["payments-table-item-property"]}>{formatDate(item.date_of_transaction)}</div>
                            <div className={styles["payments-table-item-property"]}>
                                {item.is_refill ? "-" : "+"} {formatNumber(item.amount || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles["payments-block"]}>
                <h3 className={styles["payments-header"]}>Выводы</h3>
                <div className={styles["payments-table"]}>
                    {deducts.map((item, index) => (
                        <div
                            className={styles["payments-table-item"]}
                            key={index}
                        >
                            <div className={styles["payments-table-item-property"]}>#{item.id}</div>
                            <div className={styles["payments-table-item-property"]}>{formatDate(item.date_of_transaction)}</div>
                            <div className={styles["payments-table-item-property"]}>
                                {item.is_refill ? "-" : "+"} {formatNumber(item.amount || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
}