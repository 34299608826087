import React from "react";
import styles from "../Helper.module.scss";

function HelperItem({ header, description }: { header: string; description: string }) {
    return (
        <div className={styles.item}>
            <h3 className={styles['item-header']}>{header}</h3>
            <p className={styles['item-description']}>{description}</p>
        </div>
    );
}

export default HelperItem;
