// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alert_alert-block__u2IIa {
  background-color: #211F26;
  border-radius: 10px;
  padding: 20px;
  color: #FEF7FF;
  font-family: Jost;
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  justify-content: space-around;
}
.Alert_alert-block-header__7K5Bt {
  color: red;
  text-align: center;
  font-family: Jost;
}
.Alert_alert-block-uploader__vsam9 button {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FEF7FF;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.Alert_ant-btn__pOlPu {
  height: unset;
  display: flex;
  justify-content: space-between;
}

.Alert_ant-btn__pOlPu:not(.Alert_ant-btn-icon-only__sZUBp) > .Alert_ant-btn-icon__OwSrF:not(:last-child) {
  margin-inline-end: 0;
}`, "",{"version":3,"sources":["webpack://./src/main/Alert.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,6BAAA;AACF;AACE;EACE,UAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,aAAA;EACA,8BAAA;AADF;;AAKA;EACE,oBAAA;AAFF","sourcesContent":[".alert-block {\n  background-color: #211F26;\n  border-radius: 10px;\n  padding: 20px;\n  color: #FEF7FF;\n  font-family: Jost;\n  display: flex;\n  align-items: center;\n  margin-bottom: 2%;\n  justify-content: space-around;\n\n  &-header {\n    color: red;\n    text-align: center;\n    font-family: Jost;\n  }\n\n  &-uploader button {\n    padding-top: 5px;\n    padding-bottom: 5px;\n    color: #FEF7FF;\n    display: flex;\n    flex-direction: row-reverse;\n    align-items: center;\n  }\n}\n\n.ant-btn {\n  height: unset;\n  display: flex;\n  justify-content: space-between;\n\n}\n\n.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {\n  margin-inline-end: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert-block": `Alert_alert-block__u2IIa`,
	"alert-block-header": `Alert_alert-block-header__7K5Bt`,
	"alert-block-uploader": `Alert_alert-block-uploader__vsam9`,
	"ant-btn": `Alert_ant-btn__pOlPu`,
	"ant-btn-icon-only": `Alert_ant-btn-icon-only__sZUBp`,
	"ant-btn-icon": `Alert_ant-btn-icon__OwSrF`
};
export default ___CSS_LOADER_EXPORT___;
