import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Helper from "./components/Helper/Helper";
import { HistoryOfWorks } from './components/HistoryOfWorks/HistoryOfWorks';
import { TradeList } from './components/TradeList/TradeList';
import {PaymentPage} from "./components/Payment/PaymentPage";
import {PaymentActionEnum} from "./models";
import {Profile} from "./components/Profile/Profile";
import {HistoryTransaction} from "./components/HistoryTransaction/HistoryTransaction";

function Main() {
    return (
        <div>
            <Routes>
                <Route path="help" element={<Helper/>}/>
                <Route path="profile" element={<Profile/>}/>
                <Route path="historytransaction" element={<HistoryTransaction/>}/>
                <Route path="historyofworks" element={<HistoryOfWorks/>}/>
                <Route path="tradelist" element={<TradeList/>}/>
                <Route path="paymentRefill" element={<PaymentPage actionType={PaymentActionEnum.Refill}/>}/>
                <Route path="paymentWithdraw" element={<PaymentPage actionType={PaymentActionEnum.Withdraw}/>}/>
            </Routes>
        </div>
    );
}

export default Main;
