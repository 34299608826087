import { Radio } from "antd";
import React from "react";
import { PaymentOptionEnum } from "../../../models";
import styles from "../Payment.module.scss";

export const PaymentOption = ({ selectedOption, setSelectedOption }: { selectedOption: PaymentOptionEnum, setSelectedOption: React.Dispatch<React.SetStateAction<PaymentOptionEnum>> }) => {
    return (
        <div className={styles["option-block"]}>
            <Radio.Group
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                buttonStyle="solid"
            >
                <Radio.Button value={PaymentOptionEnum.MasterCard} className={styles["payment-option"]}>
                    MasterCard
                </Radio.Button>
                <Radio.Button value={PaymentOptionEnum["FK-wallet"]} className={styles["payment-option"]}>
                    FK-wallet
                </Radio.Button>
                <Radio.Button value={PaymentOptionEnum["Ю money"]} className={styles["payment-option"]}>
                    Ю money
                </Radio.Button>
                <Radio.Button value={PaymentOptionEnum["BIT COIN"]} className={styles["payment-option"]}>
                    BIT COIN
                </Radio.Button>
                <Radio.Button value={PaymentOptionEnum["USDT trc-20"]} className={styles["payment-option"]}>
                    USDT trc-20
                </Radio.Button>
                <Radio.Button value={PaymentOptionEnum.VISA} className={styles["payment-option"]}>
                    VISA
                </Radio.Button>
            </Radio.Group>
        </div>
    );
};
