import React, { useState } from "react";
import { PaymentOption } from "./PaymentOption/PaymentOption";
import { PaymentHistory } from "./PaymentHistory/PaymentHistory";
import { PaymentAction } from "./PaymentAction/PaymentAction";
import { Header } from "../Header/Header";
import { PaymentActionEnum, PaymentOptionEnum } from "../../models";
import { Alert } from "../../Alert";
import styles from "./Payment.module.scss";

export const PaymentPage = ({ actionType }: { actionType: PaymentActionEnum }) => {
    const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOptionEnum>(PaymentOptionEnum.MasterCard);

    return (
        <div>
            <Header />
            <div className={styles["payments-page"]}>
                <PaymentOption selectedOption={selectedPaymentOption} setSelectedOption={setSelectedPaymentOption} />
                <PaymentAction actionType={actionType} selectedOption={selectedPaymentOption} />
                <PaymentHistory />
            </div>
            <div className={styles.paymentsAttention}>
                {actionType === PaymentActionEnum.Refill ? <Alert /> : null}
            </div>
        </div>
    );
};
