import React, { useEffect, useState } from "react";
import { Payment } from "../../../models";
import styles from "../Payment.module.scss";
import { formatNumber, getCurrencySymbol, getTransitionsHistory } from "../../../../api/auth.api";
import { useUser } from "../../../useUser";

export const PaymentHistory = () => {
    const user = useUser();
    const [payments, setPayments] = useState<Payment[]>([]);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const data = await getTransitionsHistory();
                setPayments(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchHistory();
    }, []);

    return (
        <div className={styles["payments-block"]}>
            <h3 className={styles["payments-header"]}>История</h3>
            <div className={styles["payments-table"]}>
                {payments.map((item, index) => (
                    <div
                        className={styles["payments-table-item"]}
                        style={{ backgroundColor: index % 2 === 0 ? "#211F26" : "#2B2830" }}
                        key={index}
                    >
                        <div className={styles["payments-table-item-property"]}>#{item.id}</div>
                        <div className={styles["payments-table-item-property"]}>{item.is_refill? 'Пополнение':'Вывод'}</div>
                        <div className={styles["payments-table-item-property"]}>
                            {item.is_refill ? "-" : "+"} { formatNumber(item.amount || 0) } {getCurrencySymbol(user.user?.profile.currency || '')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
