import React, { useEffect, useRef } from "react";

interface DiagramProps {
  specs: {
    hideSideToolbar: boolean;
    interval: string;
    style: string;
    symbol: string;
  };
}

export const Diagram: React.FC<DiagramProps> = ({ specs }) => {
  const container = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (container.current) {
      container.current.innerHTML = "";

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: specs.symbol,
        interval: specs.interval,
        timezone: "Etc/UTC",
        theme: "dark",
        style: specs.style,
        locale: "en",
        hide_top_toolbar: true,
        backgroundColor: "#211F26",
        hide_legend: true,
        hide_side_toolbar: specs.hideSideToolbar,
        allow_symbol_change: false,
        save_image: true,
        calendar: false,
        hide_volume: false,
        overrides: {
          "paneProperties.background": "#2E2E2E",
          "wickUpColor": "rgb(0, 0, 0)",
          "wickDownColor": "rgb(0, 0, 0)"
        }
      });

      container.current.appendChild(script);
    }

    return () => {
      if (container.current) {
        container.current.innerHTML = "";
      }
    };
  }, [specs.hideSideToolbar, specs.interval, specs.style, specs.symbol]);


  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
};
