import React, { useEffect, useState } from "react";
import { ProfilePicture } from "./ProfilePicture/ProfilePicture";
import { VerificationBlock } from "./VerificationBlock/VerificationBlock";
import { Alert } from "../../Alert";
import { Safety } from "./Safety/Safety";
import { Header } from "../Header/Header";
import styles from "./styles.module.scss";
import { UserInfo } from "./UserInfo/UserInfo";
import { Axios, checkVerification } from "../../../api/auth.api";
import { Profile as ProfileType } from "../../models";

export const Profile = () => {
    const [profileInfo, setProfileInfo] = useState<ProfileType | null>(null);
    const [isVerified, setIsVerified] = useState<{
        verified: boolean,
        two_level_verified: boolean
    }>({
        verified: false,
        two_level_verified: false
    });
    
    const getProfileInfo = async () => {
        const token = localStorage.getItem('token');
        await Axios.get<ProfileType>('https://oneoption.ru/api/v1/profile/me', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                setProfileInfo(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getProfileInfo();
    }, []);

    useEffect(() => {
        const fetchGetVerification = async () => {
            try {
                const data = await checkVerification();
                setIsVerified(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchGetVerification();
    }, []);

    return (
        <div>
            <Header />
            <div className={styles['profile-page']}>
                <div className={styles["profile-page_user-info"]}>
                    <ProfilePicture profileInfo={profileInfo} isVerified={isVerified.two_level_verified}></ProfilePicture>
                    <UserInfo profileInfo={profileInfo} ></UserInfo>
                </div>
                <VerificationBlock isVerified={isVerified.two_level_verified}></VerificationBlock>
                <Safety></Safety>
            </div>
        </div>
    )
}
