// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_fullPageOverlay__aN0PK {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Loading_loader__vCaIX {
    width: 45px;
    aspect-ratio: .75;
    --c: no-repeat linear-gradient(#3f0783 0 0);
    background:
        var(--c) 0% 50%,
        var(--c) 50% 50%,
        var(--c) 100% 50%;
    animation: Loading_l7__MXsqW 1s infinite linear alternate;
}

@keyframes Loading_l7__MXsqW {
    0% {
        background-size: 20% 50%, 20% 50%, 20% 50%;
    }
    20% {
        background-size: 20% 20%, 20% 50%, 20% 50%;
    }
    40% {
        background-size: 20% 100%, 20% 20%, 20% 50%;
    }
    60% {
        background-size: 20% 50%, 20% 100%, 20% 20%;
    }
    80% {
        background-size: 20% 50%, 20% 50%, 20% 100%;
    }
    100% {
        background-size: 20% 50%, 20% 50%, 20% 50%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,mCAA2B;YAA3B,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,2CAA2C;IAC3C;;;yBAGqB;IACrB,yDAA0C;AAC9C;;AAEA;IACI;QACI,0CAA0C;IAC9C;IACA;QACI,0CAA0C;IAC9C;IACA;QACI,2CAA2C;IAC/C;IACA;QACI,2CAA2C;IAC/C;IACA;QACI,2CAA2C;IAC/C;IACA;QACI,0CAA0C;IAC9C;AACJ","sourcesContent":[".fullPageOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(255, 255, 255, 0.1);\n    backdrop-filter: blur(20px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.loader {\n    width: 45px;\n    aspect-ratio: .75;\n    --c: no-repeat linear-gradient(#3f0783 0 0);\n    background:\n        var(--c) 0% 50%,\n        var(--c) 50% 50%,\n        var(--c) 100% 50%;\n    animation: l7 1s infinite linear alternate;\n}\n\n@keyframes l7 {\n    0% {\n        background-size: 20% 50%, 20% 50%, 20% 50%;\n    }\n    20% {\n        background-size: 20% 20%, 20% 50%, 20% 50%;\n    }\n    40% {\n        background-size: 20% 100%, 20% 20%, 20% 50%;\n    }\n    60% {\n        background-size: 20% 50%, 20% 100%, 20% 20%;\n    }\n    80% {\n        background-size: 20% 50%, 20% 50%, 20% 100%;\n    }\n    100% {\n        background-size: 20% 50%, 20% 50%, 20% 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullPageOverlay": `Loading_fullPageOverlay__aN0PK`,
	"loader": `Loading_loader__vCaIX`,
	"l7": `Loading_l7__MXsqW`
};
export default ___CSS_LOADER_EXPORT___;
