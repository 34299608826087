import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import slidepaneStyles from "./slidepane.module.scss";
import { CloseButton, LeaderBoardLogo, ProfileLogo, RefillLogo, DeductLogo, MonthHistoryLogo, HistoryLogo, HelpLogo, TelegramLogo, LogOutLogo, Logo } from "../../icones";
import { useLocation, useNavigate } from "react-router-dom";
import { RatingPane } from "./Rating";

interface SmallHeaderPaneProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SmallHeaderPane: React.FC<SmallHeaderPaneProps> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        isRatingPaneOpen: false,
        isSmallPaneOpen: false,
    });
    const [activePage, setActivePage] = useState(location.pathname.split("/")[2] || "");

    const notifications: Notification[] = []

    useEffect(() => {
        const currentPath = location.pathname.split("/")[2];
        setActivePage(currentPath || "");
    }, [location]);

    return (
        <SlidingPane
            className={slidepaneStyles.slidePane}
            isOpen={isOpen}
            width="100%"
            onRequestClose={onClose}
            hideHeader={true}
        >
            <header className={slidepaneStyles.notificationsHeader}>
                <div className={slidepaneStyles.slidingPaneSmallHeader}>
                    <div className={slidepaneStyles.logo} onClick={() => navigate("/main/tradeList")}>
                        <Logo />
                    </div>
                    <button onClick={onClose} className={slidepaneStyles.closeButton}>
                        <CloseButton />
                    </button>
                </div>
            </header>
            <div className={slidepaneStyles.menu}>
                <div className={slidepaneStyles.menuSale}>
                    <span>BTC - USDT</span><span>70%</span>
                </div>
                <button className={slidepaneStyles.menuItem} onClick={() => {
                    setState({ isRatingPaneOpen: true, isSmallPaneOpen: false });
                }}>
                    <LeaderBoardLogo />
                    <span>Топ пользователей</span>
                </button>

                <RatingPane
                    isOpen={state.isRatingPaneOpen}
                    onClose={() => setState({ ...state, isRatingPaneOpen: false })}
                    paneWidth="100%"
                />
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/profile")}>
                    <ProfileLogo />
                    <span>Профиль</span>
                </button>
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/paymentRefill")}>
                    <RefillLogo />
                    <span>Пополнить</span>
                </button>
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/paymentWithdraw")}>
                    <DeductLogo />
                    <span>Вывод</span>
                </button>
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/historyofworks")}>
                    <MonthHistoryLogo />
                    <span>История сделок</span>
                </button>
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/historytransaction")}>
                    <HistoryLogo />
                    <span>История транзакций</span>
                </button>
                <hr className={slidepaneStyles.divider} />
                <button className={slidepaneStyles.menuItem} onClick={() => navigate("/main/help")}>
                    <HelpLogo />
                    <span>FAQ</span>
                </button>
                <a
                    href="https://web.telegram.org/a/"
                    target="_blank"
                    className={slidepaneStyles.menuItemA}
                >
                    <TelegramLogo />
                    <span>Telegram</span>
                </a>
                <button
                    className={slidepaneStyles.menuItem}
                    onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/auth/login");
                    }}
                >
                    <LogOutLogo />
                    <span>Выйти</span>
                </button>
            </div>
        </SlidingPane>
    );
};
