import { getProfile } from "../api/auth.api";
import { useUserContext } from "../UserContext";
import { useEffect } from "react";

export const useUser = () => {
    const { user, setUser } = useUserContext();

    const fetchProfile = async () => {
        try {
            const profileData = await getProfile();
            setUser(profileData);
        } catch (error) {
            console.error("Failed to fetch profile info:", error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            fetchProfile();
        }
    }, []);

    return { user, fetchProfile };
};
