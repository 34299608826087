import React, { useState } from "react";
import {Axios, resetPassword} from "../../../../api/auth.api";
import styles from './Safety.module.scss';
export const Safety = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifPassword, setVerifPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleChangePassword = async() => {
        if (newPassword !== verifPassword) {
            setError("Новый пароль и подтверждение пароля не совпадают");
            return;
        }

        setError("");
        setSuccess("");

        try {
            const response = await resetPassword(newPassword);
            setSuccess("Пароль успешно изменен");
            setCurrentPassword('');
            setNewPassword('');
            setVerifPassword('');
        } catch (err) {
            console.error(err);
            setError("Произошла ошибка при изменении пароля");
        }
       
    };

    return (
        <div className={styles.formContainer}>
            <h2 className={styles.safetyHeader}>Безопасность</h2>
            <div className={styles.formGroupContainer}>
            <div className={styles.formGroup}>
                <label htmlFor="CurrentPassword">Текущий пароль</label>
                <input
                    placeholder="Текущий пароль"
                    type="password"
                    id="CurrentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className = {styles.inputField}
                />
            </div>

            <div className={styles.formGroup}>
            <label htmlFor="NewPassword">Новый пароль</label>
            <input
                placeholder="Новый пароль"
                type="password"
                id="NewPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className = {styles.inputField}
            /></div>

            <div className={styles.formGroup}>
            <label htmlFor="Verif">Подтверждение пароля</label>
            <input
                placeholder="Подтверждение пароля"
                type="password"
                id="Verif"
                value={verifPassword}
                onChange={(e) => setVerifPassword(e.target.value)}
                className = {styles.inputField}
                style={{marginBottom:"5%"}}
            /></div>
            </div>
            <button onClick={handleChangePassword} className={styles.saveButton}>Сменить пароль</button>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
        </div>
    );
};
