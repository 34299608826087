import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import slidepaneStyles from "./slidepane.module.scss";
import { LeaderboardUser, UserStats } from "../../models";
import { CloseButton } from "../../icones";
import { formatNumber, getCurrencySymbol, getLeaderBoard, getUserStates } from "../../../api/auth.api";
import { useUser } from "../../useUser";

interface RatingPaneProps {
    isOpen: boolean;
    onClose: () => void;
    paneWidth: string
}

export const RatingPane: React.FC<RatingPaneProps> = ({ isOpen, onClose, paneWidth }) => {
    const user = useUser();
    const [ratings, setRatings] = useState<LeaderboardUser[]>([]);

    const [monthStats, setMonthStats] = useState<UserStats>();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const data = await getUserStates();
                setMonthStats(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchStats();
    }, []);


    const getRank = (user: LeaderboardUser) => {
        return user.average_profit_today !== undefined
            ? (user.average_profit_today < 3000 ? 'Bronze' :
                user.average_profit_today < 5000 ? 'Silver' :
                    'Gold')
            : 'Unknown';
    }

    useEffect(() => {
        if (isOpen) {
            const fetchLeaderboard = async () => {
                try {
                    const data = await getLeaderBoard();
                    setRatings(data);
                } catch (error) {
                    console.error("Failed to fetch leaderboard history:", error);
                }
            };

            fetchLeaderboard();
        }
    }, [isOpen]);

    return (
        <SlidingPane
            className={slidepaneStyles.slidePane}
            isOpen={isOpen}
            width={paneWidth}
            onRequestClose={onClose}
            hideHeader={true}
        >
            <header>
                <div className={slidepaneStyles.slidingPaneHeader}>
                    <h2>Рейтинг текущего дня</h2>
                    <button onClick={onClose} className={slidepaneStyles.backIcon}>
                        <CloseButton />
                    </button>
                </div>
                <div className={slidepaneStyles.personalPosition}>
                    <div className={slidepaneStyles.personalPositionSection}>
                        <div className={slidepaneStyles.personalPositionImage}>
                            <img src={user.user?.profile.image} />
                        </div>
                        <div className={slidepaneStyles.userInfo}>
                            <h4 className={slidepaneStyles.userName}>{user.user?.profile.nickname}</h4>
                            <p className={slidepaneStyles.userPosition}>Ваша позиция: { }</p>
                        </div>
                    </div>
                    <div className={slidepaneStyles.personalPositionSection}>
                        <p className={slidepaneStyles.userSum}>
                            {formatNumber(monthStats?.monthly_profit || 0)}{" "}
                            {getCurrencySymbol(user.user?.profile.currency || "")}</p>
                    </div>
                </div>
            </header>
            <div className={slidepaneStyles.ratingBoard}>
                {ratings.map((leader, index) => (
                    <details
                        key={index}
                        className={slidepaneStyles.ratingBackground}
                        style={
                            getRank(leader) === "Silver"
                                ? { backgroundColor: "#868686" }
                                : getRank(leader) === "Bronze"
                                    ? { backgroundColor: "#FF8551" }
                                    : getRank(leader) === "Gold"
                                        ? { backgroundColor: "#FF993A" }
                                        : {}
                        }
                    >
                        <summary className={slidepaneStyles.userPosition}>
                            <div className={slidepaneStyles.personalPositionSection}>
                                <p className={slidepaneStyles.userSum}># {formatNumber(leader.user_id)} </p>
                                <div className={slidepaneStyles.userInfo}>
                                    <h4 className={slidepaneStyles.userName}>{leader.flag}</h4>
                                </div>
                            </div>
                            <div className={slidepaneStyles.personalPositionSection}>
                                <p className={slidepaneStyles.userSum}>$ {leader.average_profit_today}</p>
                            </div>
                        </summary>
                        <div className={slidepaneStyles.ratingDetails}>
                            <div>
                                <p>Всего Побед:</p>
                                <p>{leader.total_wins_today}
                                    {getCurrencySymbol(user.user?.profile.currency || "")} </p>
                            </div>
                            <div>
                                <p>Среднии Прибыль:</p>
                                <p>{leader.average_profit_today}
                                    {getCurrencySymbol(user.user?.profile.currency || "")} </p>
                            </div>
                            <div>
                                <p>Минимальная ставка :</p>
                                <p>{leader.min_bet_today}
                                    {getCurrencySymbol(user.user?.profile.currency || "")} </p>
                            </div>
                            <div>
                                <p>Максимальная ставка:</p>
                                <p>{leader.max_bet_today}
                                    {getCurrencySymbol(user.user?.profile.currency || "")} </p>
                            </div>
                        </div>
                    </details>
                ))}
            </div>
        </SlidingPane>
    );
};
