export const Logo = () => {
    return (
        <svg width="109" height="62" viewBox="0 0 109 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.3866 21.1967V44.33L26.6661 44.33C44.3866 36.6364 40.4246 15.9343 30.3402 9.91998H47.1568L62.4125 29.8819V9.91998H73.1004V44.33H59.8403L44.3866 21.1967Z" fill="white" />
            <path d="M109 44.33H76.9808V9.91998H109V18.1149H87.6686V23.3914H108.978V29.952H87.6686V35.2751H109V44.33Z" fill="white" />
            <path d="M14.6416 14.2253L14.6392 14.2261V7.73177C10.4214 8.58832 6.7514 10.8731 4.04606 14.2957C1.14442 17.9666 -0.284067 22.616 0.0469461 27.3118C0.37796 32.0076 2.444 36.4028 5.83093 39.6164C9.21786 42.8299 13.6754 44.6243 18.3099 44.6399C22.9445 44.6555 27.4136 42.8911 30.8214 39.7004C34.2292 36.5097 36.3239 32.1285 36.6855 27.435C37.0471 22.7416 35.6489 18.0827 32.7713 14.3923C30.7728 11.8294 28.1633 9.86512 25.2211 8.66395L21.5282 0V14.056L21.5258 14.0554V17.4483C24.9688 18.745 27.4216 22.1025 27.4216 26.04C27.4216 31.101 23.3694 35.2038 18.3708 35.2038C13.3721 35.2038 9.31988 31.101 9.31988 26.04C9.31988 23.0046 10.7775 20.3139 13.0227 18.6463L14.6392 22.7577V17.6888L14.6416 17.6877V14.2253Z" fill="white" />
            <path d="M18.0102 47.7805C20.5829 47.7805 22.6492 49.8466 22.6492 52.4191V57.3412C22.6492 59.9137 20.5829 62 18.0102 62H5.86362C3.29092 62 1.22466 59.9137 1.22466 57.3412V52.4191C1.22466 49.8466 3.29092 47.7805 5.86362 47.7805H18.0102ZM19.1244 57.3412V52.4191C19.1244 51.7911 18.6382 51.305 18.0102 51.305H5.86362C5.23564 51.305 4.74946 51.7911 4.74946 52.4191V57.3412C4.74946 57.9691 5.23564 58.4553 5.86362 58.4553H18.0102C18.6382 58.4553 19.1244 57.9691 19.1244 57.3412Z" fill="white" />
            <path d="M35.7236 47.7603C38.1748 47.7603 40.16 49.7453 40.16 52.176C40.16 54.6269 38.1748 56.612 35.7236 56.612L27.8522 56.6322V61.9797H24.3274V47.7805L35.7236 47.7603ZM35.7236 53.0875C36.23 53.0875 36.6149 52.6824 36.6149 52.176C36.6149 51.6899 36.23 51.2847 35.7236 51.2847L27.8522 51.305V53.0875H35.7236Z" fill="white" />
            <path d="M59.1677 47.7805V51.305H51.7332V61.9797H48.2084V51.305H40.7941V47.7805H59.1677Z" fill="white" />
            <path d="M64.1447 61.9797H60.6199V47.74H64.1447V61.9797Z" fill="white" />
            <path d="M80.0654 47.7805C82.6381 47.7805 84.7044 49.8466 84.7044 52.4191V57.3412C84.7044 59.9137 82.6381 62 80.0654 62H70.9091C68.3364 62 66.2701 59.9137 66.2701 57.3412V52.4191C66.2701 49.8466 68.3364 47.7805 70.9091 47.7805H80.0654ZM81.1796 57.3412V52.4191C81.1796 51.7911 80.6934 51.305 80.0654 51.305H70.9091C70.2811 51.305 69.7949 51.7911 69.7949 52.4191V57.3412C69.7949 57.9691 70.2811 58.4553 70.9091 58.4553H80.0654C80.6934 58.4553 81.1796 57.9691 81.1796 57.3412Z" fill="white" />
            <path d="M108.959 61.9595L109 61.9797H103.895L90.3567 52.6014V61.9797H86.8319V47.74H90.377L105.434 58.5768V47.74H108.959V61.9595Z" fill="white" />
            <path d="M12.9698 27.0247L11.2986 28.4952L12.7691 31.0248L14.9014 30.3552C15.171 30.5536 15.4347 30.7212 15.6926 30.8581C15.9504 30.995 16.2259 31.125 16.519 31.248L16.9602 33.48H19.9013L20.3425 31.248C20.6366 31.124 20.9125 30.994 21.1704 30.8581C21.4282 30.7222 21.6914 30.5546 21.9601 30.3552L24.0924 31.0248L25.5629 28.4952L23.8718 27.0072C23.9208 26.6848 23.9453 26.3624 23.9453 26.04C23.9453 25.7176 23.9208 25.3952 23.8718 25.0728L25.5629 23.5848L24.0924 21.0552L21.9601 21.7248C21.6905 21.5264 21.4272 21.3587 21.1704 21.2218C20.9135 21.0849 20.6375 20.955 20.3425 20.832L19.9013 18.6H17.026V23.4207C17.4472 23.1835 17.9154 23.0646 18.4307 23.064C19.2395 23.064 19.9322 23.3556 20.5086 23.9389C21.0851 24.5222 21.3728 25.2226 21.3718 26.04C21.3718 26.8584 21.0841 27.5592 20.5086 28.1425C19.9331 28.7258 19.2405 29.017 18.4307 29.016C17.6219 29.016 16.9298 28.7248 16.3543 28.1425C16.0245 27.8088 15.7891 27.4362 15.648 27.0247H12.9698Z" fill="white" />
        </svg>
    );
};

export const LeaderBoardLogo = () => {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9998 5.10425C12.2661 5.10425 10.0519 7.31844 10.0519 10.0522C10.0519 12.7859 12.2661 15.0001 14.9998 15.0001C17.7336 15.0001 19.9478 12.7859 19.9478 10.0522C19.9478 7.31844 17.7336 5.10425 14.9998 5.10425ZM17.4738 10.0522C17.4738 8.69149 16.3605 7.57821 14.9998 7.57821C13.6392 7.57821 12.5259 8.69149 12.5259 10.0522C12.5259 11.4128 13.6392 12.5261 14.9998 12.5261C16.3605 12.5261 17.4738 11.4128 17.4738 10.0522ZM22.4217 21.185C22.1743 20.3067 18.3397 18.711 14.9998 18.711C11.66 18.711 7.82536 20.3067 7.57796 21.1973V22.422H22.4217V21.185ZM5.104 21.185C5.104 17.8946 11.6971 16.2371 14.9998 16.2371C18.3026 16.2371 24.8957 17.8946 24.8957 21.185V24.8959H5.104V21.185Z" fill="#FEF7FF" />
        <path d="M5.72266 8.50586L6.7641 11.7111L10.1343 11.7111L7.40775 13.6921L8.4492 16.8973L5.72266 14.9163L2.99611 16.8973L4.03756 13.6921L1.31102 11.7111L4.68121 11.7111L5.72266 8.50586Z" fill="#FEF7FF" />
    </svg>);
}

export const ProfileLogo = () => {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 0.104004C7.26611 0.104004 5.05192 2.3182 5.05192 5.05192C5.05192 7.78565 7.26611 9.99984 9.99984 9.99984C12.7336 9.99984 14.9478 7.78565 14.9478 5.05192C14.9478 2.3182 12.7336 0.104004 9.99984 0.104004ZM12.4738 5.05192C12.4738 3.69124 11.3605 2.57796 9.99984 2.57796C8.63916 2.57796 7.52588 3.69124 7.52588 5.05192C7.52588 6.4126 8.63916 7.52588 9.99984 7.52588C11.3605 7.52588 12.4738 6.4126 12.4738 5.05192ZM17.4217 16.1847C17.1743 15.3065 13.3397 13.7108 9.99984 13.7108C6.65999 13.7108 2.82536 15.3065 2.57796 16.1971V17.4217H17.4217V16.1847ZM0.104004 16.1847C0.104004 12.8944 6.6971 11.2368 9.99984 11.2368C13.3026 11.2368 19.8957 12.8944 19.8957 16.1847V19.8957H0.104004V16.1847Z" fill="#FEF7FF" />
    </svg>

    );
}
export const RefillLogo = () => {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.6387 17.7832C19.3926 17.7832 19.1566 17.8809 18.9827 18.0549C18.8087 18.2289 18.7109 18.4649 18.7109 18.7109C18.7109 18.957 18.8087 19.193 18.9827 19.3669C19.1566 19.5409 19.3926 19.6387 19.6387 19.6387H23.3496C23.5957 19.6387 23.8316 19.5409 24.0056 19.3669C24.1796 19.193 24.2773 18.957 24.2773 18.7109C24.2773 18.4649 24.1796 18.2289 24.0056 18.0549C23.8316 17.8809 23.5957 17.7832 23.3496 17.7832H19.6387ZM2.01172 10.3613C2.01172 9.13108 2.50043 7.95121 3.37035 7.08129C4.24027 6.21137 5.42014 5.72266 6.65039 5.72266H23.3496C24.5799 5.72266 25.7597 6.21137 26.6296 7.08129C27.4996 7.95121 27.9883 9.13108 27.9883 10.3613V19.6387C27.9883 20.8689 27.4996 22.0488 26.6296 22.9187C25.7597 23.7886 24.5799 24.2773 23.3496 24.2773H6.65039C5.42014 24.2773 4.24027 23.7886 3.37035 22.9187C2.50043 22.0488 2.01172 20.8689 2.01172 19.6387V10.3613ZM26.1328 11.2891V10.3613C26.1328 9.62318 25.8396 8.91526 25.3176 8.39331C24.7957 7.87135 24.0878 7.57812 23.3496 7.57812H6.65039C5.91224 7.57812 5.20432 7.87135 4.68237 8.39331C4.16042 8.91526 3.86719 9.62318 3.86719 10.3613V11.2891H26.1328ZM3.86719 13.1445V19.6387C3.86719 20.3768 4.16042 21.0847 4.68237 21.6067C5.20432 22.1286 5.91224 22.4219 6.65039 22.4219H23.3496C24.0878 22.4219 24.7957 22.1286 25.3176 21.6067C25.8396 21.0847 26.1328 20.3768 26.1328 19.6387V13.1445H3.86719Z" fill="#FEF7FF" />
        <rect x="20.5664" y="15.9277" width="1.85547" height="5.56641" rx="0.927734" fill="#FEF7FF" />
    </svg>
    );
}
export const DeductLogo = () => {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.6387 18.7109C19.3926 18.7109 19.1566 18.8087 18.9827 18.9827C18.8087 19.1566 18.7109 19.3926 18.7109 19.6387C18.7109 19.8847 18.8087 20.1207 18.9827 20.2947C19.1566 20.4687 19.3926 20.5664 19.6387 20.5664H23.3496C23.5957 20.5664 23.8316 20.4687 24.0056 20.2947C24.1796 20.1207 24.2773 19.8847 24.2773 19.6387C24.2773 19.3926 24.1796 19.1566 24.0056 18.9827C23.8316 18.8087 23.5957 18.7109 23.3496 18.7109H19.6387ZM2.01172 10.3613C2.01172 9.13108 2.50043 7.95121 3.37035 7.08129C4.24027 6.21137 5.42014 5.72266 6.65039 5.72266H23.3496C24.5799 5.72266 25.7597 6.21137 26.6296 7.08129C27.4996 7.95121 27.9883 9.13108 27.9883 10.3613V19.6387C27.9883 20.8689 27.4996 22.0488 26.6296 22.9187C25.7597 23.7886 24.5799 24.2773 23.3496 24.2773H6.65039C5.42014 24.2773 4.24027 23.7886 3.37035 22.9187C2.50043 22.0488 2.01172 20.8689 2.01172 19.6387V10.3613ZM26.1328 11.2891V10.3613C26.1328 9.62318 25.8396 8.91526 25.3176 8.39331C24.7957 7.87135 24.0878 7.57812 23.3496 7.57812H6.65039C5.91224 7.57812 5.20432 7.87135 4.68237 8.39331C4.16042 8.91526 3.86719 9.62318 3.86719 10.3613V11.2891H26.1328ZM3.86719 13.1445V19.6387C3.86719 20.3768 4.16042 21.0847 4.68237 21.6067C5.20432 22.1286 5.91224 22.4219 6.65039 22.4219H23.3496C24.0878 22.4219 24.7957 22.1286 25.3176 21.6067C25.8396 21.0847 26.1328 20.3768 26.1328 19.6387V13.1445H3.86719Z" fill="#FEF7FF" />
    </svg>
    );
}
export const MonthHistoryLogo = () => {
    return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 0C10.7584 0 10.5185 0.00780094 10.2805 0.0231764L10.3692 1.39531C10.5777 1.38184 10.788 1.375 11 1.375C11.212 1.375 11.4223 1.38184 11.6308 1.39531L11.7195 0.0231764C11.4815 0.00780098 11.2416 0 11 0Z"
            fill="white" />
        <path
            d="M8.85397 0.20936C8.37863 0.303395 7.91425 0.428057 7.46299 0.581205L7.90489 1.88326C8.29929 1.74941 8.70521 1.64044 9.12081 1.55822L8.85397 0.20936Z"
            fill="white" />
        <path
            d="M14.537 0.581205C14.0858 0.428057 13.6214 0.303395 13.146 0.20936L12.8792 1.55822C13.2948 1.64044 13.7007 1.74941 14.0951 1.88326L14.537 0.581205Z"
            fill="white" />
        <path
            d="M17.1123 1.85319C16.7147 1.58702 16.2987 1.34604 15.8666 1.13255L15.2576 2.36529C15.6354 2.552 15.9994 2.76282 16.3473 2.99577L17.1123 1.85319Z"
            fill="white" />
        <path
            d="M6.13337 1.13255C5.70128 1.34604 5.28532 1.58702 4.88774 1.85319L5.65268 2.99577C6.00065 2.76282 6.36457 2.552 6.74244 2.3653L6.13337 1.13255Z"
            fill="white" />
        <path
            d="M3.74694 2.7298C3.38622 3.0464 3.0464 3.38622 2.7298 3.74694L3.76323 4.65395C4.04047 4.33806 4.33806 4.04047 4.65395 3.76323L3.74694 2.7298Z"
            fill="white" />
        <path
            d="M19.2702 3.74694C18.9536 3.38622 18.6138 3.0464 18.2531 2.7298L17.3461 3.76323C17.6619 4.04047 17.9595 4.33806 18.2368 4.65395L19.2702 3.74694Z"
            fill="white" />
        <path
            d="M20.8674 6.13337C20.654 5.70128 20.413 5.28532 20.1468 4.88774L19.0042 5.65268C19.2372 6.00065 19.448 6.36457 19.6347 6.74244L20.8674 6.13337Z"
            fill="white" />
        <path
            d="M1.85319 4.88774C1.58702 5.28532 1.34604 5.70129 1.13255 6.13337L2.36529 6.74245C2.552 6.36457 2.76282 6.00065 2.99577 5.65268L1.85319 4.88774Z"
            fill="white" />
        <path
            d="M0.581205 7.46299C0.428057 7.91425 0.303395 8.37863 0.20936 8.85397L1.55822 9.12081C1.64044 8.70521 1.74941 8.29929 1.88326 7.90489L0.581205 7.46299Z"
            fill="white" />
        <path
            d="M21.7906 8.85397C21.6966 8.37863 21.5719 7.91425 21.4188 7.46299L20.1167 7.90489C20.2506 8.29929 20.3596 8.70521 20.4418 9.12081L21.7906 8.85397Z"
            fill="white" />
        <path
            d="M0.0231764 10.2805C0.00780098 10.5185 0 10.7584 0 11C0 11.2416 0.00780094 11.4815 0.0231764 11.7194L1.39531 11.6308C1.38184 11.4223 1.375 11.212 1.375 11C1.375 10.788 1.38184 10.5777 1.39531 10.3692L0.0231764 10.2805Z"
            fill="white" />
        <path
            d="M22 11C22 10.7584 21.9922 10.5185 21.9768 10.2805L20.6047 10.3692C20.6182 10.5777 20.625 10.788 20.625 11C20.625 11.212 20.6182 11.4223 20.6047 11.6308L21.9768 11.7195C21.9922 11.4815 22 11.2416 22 11Z"
            fill="white" />
        <path
            d="M0.20936 13.146C0.303395 13.6214 0.428057 14.0857 0.581205 14.537L1.88326 14.0951C1.74941 13.7007 1.64044 13.2948 1.55822 12.8792L0.20936 13.146Z"
            fill="white" />
        <path
            d="M21.4188 14.537C21.5719 14.0858 21.6966 13.6214 21.7906 13.146L20.4418 12.8792C20.3596 13.2948 20.2506 13.7007 20.1167 14.0951L21.4188 14.537Z"
            fill="white" />
        <path
            d="M1.13255 15.8666C1.34604 16.2987 1.58702 16.7147 1.85319 17.1123L2.99577 16.3473C2.76282 15.9994 2.552 15.6354 2.3653 15.2576L1.13255 15.8666Z"
            fill="white" />
        <path
            d="M20.1468 17.1123C20.413 16.7147 20.654 16.2987 20.8674 15.8666L19.6347 15.2576C19.448 15.6354 19.2372 15.9994 19.0042 16.3473L20.1468 17.1123Z"
            fill="white" />
        <path
            d="M2.7298 18.2531C3.0464 18.6138 3.38622 18.9536 3.74694 19.2702L4.65395 18.2368C4.33806 17.9595 4.04047 17.6619 3.76323 17.3461L2.7298 18.2531Z"
            fill="white" />
        <path
            d="M18.2531 19.2702C18.6138 18.9536 18.9536 18.6138 19.2702 18.2531L18.2368 17.3461C17.9595 17.6619 17.6619 17.9595 17.3461 18.2368L18.2531 19.2702Z"
            fill="white" />
        <path
            d="M15.8666 20.8674C16.2987 20.654 16.7147 20.413 17.1123 20.1468L16.3473 19.0042C15.9994 19.2372 15.6354 19.448 15.2576 19.6347L15.8666 20.8674Z"
            fill="white" />
        <path
            d="M4.88774 20.1468C5.28532 20.413 5.70129 20.654 6.13337 20.8674L6.74245 19.6347C6.36457 19.448 6.00065 19.2372 5.65268 19.0042L4.88774 20.1468Z"
            fill="white" />
        <path
            d="M7.46299 21.4188C7.91425 21.5719 8.37863 21.6966 8.85397 21.7906L9.12081 20.4418C8.70521 20.3596 8.29929 20.2506 7.90489 20.1167L7.46299 21.4188Z"
            fill="white" />
        <path
            d="M13.146 21.7906C13.6214 21.6966 14.0857 21.5719 14.537 21.4188L14.0951 20.1167C13.7007 20.2506 13.2948 20.3596 12.8792 20.4418L13.146 21.7906Z"
            fill="white" />
        <path
            d="M10.2805 21.9768C10.5185 21.9922 10.7584 22 11 22C11.2416 22 11.4815 21.9922 11.7194 21.9768L11.6308 20.6047C11.4223 20.6182 11.212 20.625 11 20.625C10.788 20.625 10.5777 20.6182 10.3692 20.6047L10.2805 21.9768Z"
            fill="white" />
        <path
            d="M7.6382 12.2764C7.5535 12.107 7.36454 12.0174 7.21027 11.9076C7.083 11.8169 7 11.6681 7 11.5V9.5C7 9.33185 7.083 9.18309 7.21027 9.09245C7.36454 8.98259 7.5535 8.893 7.6382 8.72361L8 8L8.3618 8.72361C8.4465 8.893 8.63546 8.98259 8.78973 9.09245C8.917 9.18309 9 9.33185 9 9.5V11.5C9 11.6681 8.917 11.8169 8.78973 11.9076C8.63546 12.0174 8.4465 12.107 8.3618 12.2764L8 13L7.6382 12.2764Z"
            fill="white" />
        <path
            d="M13.6382 13.2764C13.5535 13.107 13.3645 13.0174 13.2103 12.9076C13.083 12.8169 13 12.6681 13 12.5V8.5C13 8.33185 13.083 8.18309 13.2103 8.09245C13.3645 7.98259 13.5535 7.893 13.6382 7.72361L14 7L14.3618 7.72361C14.4465 7.893 14.6355 7.98259 14.7897 8.09245C14.917 8.18309 15 8.33185 15 8.5V12.5C15 12.6681 14.917 12.8169 14.7897 12.9076C14.6355 13.0174 14.4465 13.107 14.3618 13.2764L14 14L13.6382 13.2764Z"
            fill="white" />
        <path
            d="M10.5947 15.3787C10.539 15.1561 10.3111 15.0167 10.1481 14.8552C10.0566 14.7646 10 14.6389 10 14.5V8.5C10 8.37302 10.0473 8.2571 10.1253 8.16892C10.2872 7.98582 10.5295 7.82329 10.5696 7.5822L11 5L11.4304 7.5822C11.4705 7.82329 11.7128 7.98582 11.8747 8.16892C11.9527 8.2571 12 8.37302 12 8.5V14.5C12 14.6389 11.9434 14.7646 11.8519 14.8552C11.6889 15.0167 11.461 15.1561 11.4053 15.3787L11 17L10.5947 15.3787Z"
            fill="white" />
    </svg>);
}
export const HistoryLogo = () => {
    return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 0C10.7584 0 10.5185 0.00780094 10.2805 0.0231764L10.3692 1.39531C10.5777 1.38184 10.788 1.375 11 1.375C11.212 1.375 11.4223 1.38184 11.6308 1.39531L11.7195 0.0231764C11.4815 0.00780098 11.2416 0 11 0Z"
            fill="white" />
        <path
            d="M8.85397 0.20936C8.37863 0.303395 7.91425 0.428057 7.46299 0.581205L7.90489 1.88326C8.29929 1.74941 8.70521 1.64044 9.12081 1.55822L8.85397 0.20936Z"
            fill="white" />
        <path
            d="M14.537 0.581205C14.0858 0.428057 13.6214 0.303395 13.146 0.20936L12.8792 1.55822C13.2948 1.64044 13.7007 1.74941 14.0951 1.88326L14.537 0.581205Z"
            fill="white" />
        <path
            d="M17.1123 1.85319C16.7147 1.58702 16.2987 1.34604 15.8666 1.13255L15.2576 2.36529C15.6354 2.552 15.9994 2.76282 16.3473 2.99577L17.1123 1.85319Z"
            fill="white" />
        <path
            d="M6.13337 1.13255C5.70128 1.34604 5.28532 1.58702 4.88774 1.85319L5.65268 2.99577C6.00065 2.76282 6.36457 2.552 6.74244 2.3653L6.13337 1.13255Z"
            fill="white" />
        <path
            d="M3.74694 2.7298C3.38622 3.0464 3.0464 3.38622 2.7298 3.74694L3.76323 4.65395C4.04047 4.33806 4.33806 4.04047 4.65395 3.76323L3.74694 2.7298Z"
            fill="white" />
        <path
            d="M19.2702 3.74694C18.9536 3.38622 18.6138 3.0464 18.2531 2.7298L17.3461 3.76323C17.6619 4.04047 17.9595 4.33806 18.2368 4.65395L19.2702 3.74694Z"
            fill="white" />
        <path
            d="M20.8674 6.13337C20.654 5.70128 20.413 5.28532 20.1468 4.88774L19.0042 5.65268C19.2372 6.00065 19.448 6.36457 19.6347 6.74244L20.8674 6.13337Z"
            fill="white" />
        <path
            d="M1.85319 4.88774C1.58702 5.28532 1.34604 5.70129 1.13255 6.13337L2.36529 6.74245C2.552 6.36457 2.76282 6.00065 2.99577 5.65268L1.85319 4.88774Z"
            fill="white" />
        <path
            d="M0.581205 7.46299C0.428057 7.91425 0.303395 8.37863 0.20936 8.85397L1.55822 9.12081C1.64044 8.70521 1.74941 8.29929 1.88326 7.90489L0.581205 7.46299Z"
            fill="white" />
        <path
            d="M21.7906 8.85397C21.6966 8.37863 21.5719 7.91425 21.4188 7.46299L20.1167 7.90489C20.2506 8.29929 20.3596 8.70521 20.4418 9.12081L21.7906 8.85397Z"
            fill="white" />
        <path
            d="M0.0231764 10.2805C0.00780098 10.5185 0 10.7584 0 11C0 11.2416 0.00780094 11.4815 0.0231764 11.7194L1.39531 11.6308C1.38184 11.4223 1.375 11.212 1.375 11C1.375 10.788 1.38184 10.5777 1.39531 10.3692L0.0231764 10.2805Z"
            fill="white" />
        <path
            d="M22 11C22 10.7584 21.9922 10.5185 21.9768 10.2805L20.6047 10.3692C20.6182 10.5777 20.625 10.788 20.625 11C20.625 11.212 20.6182 11.4223 20.6047 11.6308L21.9768 11.7195C21.9922 11.4815 22 11.2416 22 11Z"
            fill="white" />
        <path
            d="M0.20936 13.146C0.303395 13.6214 0.428057 14.0857 0.581205 14.537L1.88326 14.0951C1.74941 13.7007 1.64044 13.2948 1.55822 12.8792L0.20936 13.146Z"
            fill="white" />
        <path
            d="M21.4188 14.537C21.5719 14.0858 21.6966 13.6214 21.7906 13.146L20.4418 12.8792C20.3596 13.2948 20.2506 13.7007 20.1167 14.0951L21.4188 14.537Z"
            fill="white" />
        <path
            d="M1.13255 15.8666C1.34604 16.2987 1.58702 16.7147 1.85319 17.1123L2.99577 16.3473C2.76282 15.9994 2.552 15.6354 2.3653 15.2576L1.13255 15.8666Z"
            fill="white" />
        <path
            d="M20.1468 17.1123C20.413 16.7147 20.654 16.2987 20.8674 15.8666L19.6347 15.2576C19.448 15.6354 19.2372 15.9994 19.0042 16.3473L20.1468 17.1123Z"
            fill="white" />
        <path
            d="M2.7298 18.2531C3.0464 18.6138 3.38622 18.9536 3.74694 19.2702L4.65395 18.2368C4.33806 17.9595 4.04047 17.6619 3.76323 17.3461L2.7298 18.2531Z"
            fill="white" />
        <path
            d="M18.2531 19.2702C18.6138 18.9536 18.9536 18.6138 19.2702 18.2531L18.2368 17.3461C17.9595 17.6619 17.6619 17.9595 17.3461 18.2368L18.2531 19.2702Z"
            fill="white" />
        <path
            d="M15.8666 20.8674C16.2987 20.654 16.7147 20.413 17.1123 20.1468L16.3473 19.0042C15.9994 19.2372 15.6354 19.448 15.2576 19.6347L15.8666 20.8674Z"
            fill="white" />
        <path
            d="M4.88774 20.1468C5.28532 20.413 5.70129 20.654 6.13337 20.8674L6.74245 19.6347C6.36457 19.448 6.00065 19.2372 5.65268 19.0042L4.88774 20.1468Z"
            fill="white" />
        <path
            d="M7.46299 21.4188C7.91425 21.5719 8.37863 21.6966 8.85397 21.7906L9.12081 20.4418C8.70521 20.3596 8.29929 20.2506 7.90489 20.1167L7.46299 21.4188Z"
            fill="white" />
        <path
            d="M13.146 21.7906C13.6214 21.6966 14.0857 21.5719 14.537 21.4188L14.0951 20.1167C13.7007 20.2506 13.2948 20.3596 12.8792 20.4418L13.146 21.7906Z"
            fill="white" />
        <path
            d="M10.2805 21.9768C10.5185 21.9922 10.7584 22 11 22C11.2416 22 11.4815 21.9922 11.7194 21.9768L11.6308 20.6047C11.4223 20.6182 11.212 20.625 11 20.625C10.788 20.625 10.5777 20.6182 10.3692 20.6047L10.2805 21.9768Z"
            fill="white" />
        <path
            d="M11 7C11.3314 7 11.6 7.28782 11.6 7.64286V9.57143H13.4C13.7314 9.57143 14 9.85925 14 10.2143C14 10.5693 13.7314 10.8571 13.4 10.8571H11.6V12.7857C11.6 13.1408 11.3314 13.4286 11 13.4286C10.6686 13.4286 10.4 13.1408 10.4 12.7857V10.8571H8.6C8.26863 10.8571 8 10.5693 8 10.2143C8 9.85925 8.26863 9.57143 8.6 9.57143H10.4V7.64286C10.4 7.28782 10.6686 7 11 7Z"
            fill="white" />
        <path
            d="M8 15.3571C8 15.0021 8.26863 14.7143 8.6 14.7143H13.4C13.7314 14.7143 14 15.0021 14 15.3571C14 15.7122 13.7314 16 13.4 16H8.6C8.26863 16 8 15.7122 8 15.3571Z"
            fill="white" />
    </svg>
    );
}
export const HelpLogo = () => {
    return (<svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.068 17.136V15.876C4.068 14.292 4.332 13.056 4.86 12.168C5.388 11.28 6.024 10.62 6.768 10.188C7.512 9.756 8.28 9.492 9.072 9.396C9.888 9.276 10.56 9.204 11.088 9.18C11.952 9.132 12.624 9.036 13.104 8.892C13.608 8.724 13.86 8.28 13.86 7.56C13.86 7.344 13.812 7.14 13.716 6.948C13.644 6.732 13.452 6.54 13.14 6.372C12.852 6.204 12.396 6.072 11.772 5.976C11.172 5.856 10.356 5.796 9.324 5.796C7.788 5.796 6.24 5.916 4.68 6.156C3.12 6.396 1.56 6.72 0 7.128V1.188C1.296 0.804001 2.628 0.516001 3.996 0.324C5.388 0.108 6.828 0 8.316 0H9.612C11.7 0 13.428 0.192 14.796 0.576001C16.164 0.960001 17.256 1.476 18.072 2.124C18.912 2.772 19.5 3.528 19.836 4.392C20.172 5.256 20.34 6.156 20.34 7.092V7.56C20.34 8.712 20.184 9.684 19.872 10.476C19.56 11.268 19.152 11.916 18.648 12.42C18.144 12.924 17.568 13.32 16.92 13.608C16.296 13.896 15.672 14.112 15.048 14.256C14.424 14.376 13.836 14.46 13.284 14.508C12.732 14.556 12.276 14.592 11.916 14.616C11.436 14.664 11.088 14.772 10.872 14.94C10.656 15.084 10.548 15.528 10.548 16.272V17.136H4.068ZM3.744 22.14C3.744 22.98 3.984 23.7 4.464 24.3C4.944 24.9 5.856 25.2 7.2 25.2H7.488C8.832 25.2 9.744 24.9 10.224 24.3C10.704 23.7 10.944 22.98 10.944 22.14V21.708C10.944 21.3 10.884 20.916 10.764 20.556C10.668 20.172 10.488 19.848 10.224 19.584C9.96 19.296 9.6 19.068 9.144 18.9C8.712 18.732 8.16 18.648 7.488 18.648H7.2C6.528 18.648 5.964 18.732 5.508 18.9C5.076 19.068 4.728 19.296 4.464 19.584C4.2 19.848 4.008 20.172 3.888 20.556C3.792 20.916 3.744 21.3 3.744 21.708V22.14Z" fill="white" />
    </svg>
    );
}
export const LogOutLogo = () => {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2887 22.4217L7.5778 22.4217L7.5778 7.57796L11.2887 7.57796L11.2887 5.104L7.5778 5.104C6.21712 5.104 5.10384 6.21728 5.10384 7.57796L5.10384 22.4217C5.10384 23.7824 6.21712 24.8957 7.5778 24.8957L11.2887 24.8957L11.2887 22.4217ZM18.7106 8.81494L16.9665 10.5591L20.1579 13.7629L10.0518 13.7629L10.0518 16.2368L20.1579 16.2368L16.9665 19.4406L18.7106 21.1847L24.8955 14.9998L18.7106 8.81494Z" fill="#FEF7FF" />
    </svg>
    );
}
export const TelegramLogo = () => {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9997 2.62988C8.17155 2.62988 2.62988 8.17155 2.62988 14.9997C2.62988 21.8278 8.17155 27.3695 14.9997 27.3695C21.8278 27.3695 27.3695 21.8278 27.3695 14.9997C27.3695 8.17155 21.8278 2.62988 14.9997 2.62988ZM20.7393 11.0413C20.5537 12.9958 19.7497 17.7458 19.3415 19.9352C19.1683 20.863 18.8219 21.1722 18.5003 21.2093C17.7829 21.2712 17.2386 20.7393 16.5459 20.2816C15.4574 19.5641 14.8389 19.1188 13.7874 18.4261C12.5628 17.6221 13.3545 17.1768 14.0596 16.4593C14.2451 16.2738 17.4118 13.3916 17.4736 13.1318C17.4822 13.0925 17.4811 13.0516 17.4703 13.0128C17.4595 12.974 17.4394 12.9385 17.4118 12.9092C17.3376 12.8473 17.2386 12.8721 17.152 12.8844C17.0407 12.9092 15.3089 14.0596 11.932 16.3356C11.4372 16.6696 10.9919 16.8428 10.596 16.8304C10.1507 16.818 9.30957 16.583 8.67871 16.3727C7.89941 16.1253 7.29329 15.9893 7.34277 15.5563C7.36751 15.3337 7.67676 15.111 8.25814 14.876C11.8701 13.305 14.2699 12.266 15.4697 11.7712C18.9085 10.3363 19.6136 10.0889 20.0837 10.0889C20.1826 10.0889 20.4176 10.1136 20.5661 10.2373C20.6898 10.3363 20.7269 10.4723 20.7393 10.5713C20.7269 10.6455 20.7516 10.8682 20.7393 11.0413Z" fill="#FEF7FF" />
    </svg>
    );
}
export const NotificationLogo = () => {
    return (<svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5003 23.2279C26.379 22.0115 24.281 20.1816 24.281 14.1875C24.281 9.63477 21.1193 5.99023 16.856 5.09609V3.875C16.856 2.83965 16.0249 2 15 2C13.9751 2 13.144 2.83965 13.144 3.875V5.09609C8.88073 5.99023 5.71896 9.63477 5.71896 14.1875C5.71896 20.1816 3.62097 22.0115 2.49973 23.2279C2.15151 23.6059 1.99714 24.0576 2.00004 24.5C2.00642 25.4609 2.75334 26.375 3.86298 26.375H26.137C27.2467 26.375 27.9942 25.4609 28 24.5C28.0029 24.0576 27.8485 23.6053 27.5003 23.2279ZM5.91918 23.5625C7.15069 21.9236 8.49712 19.2072 8.5035 14.2215C8.5035 14.2098 8.50002 14.1992 8.50002 14.1875C8.50002 10.5629 11.4099 7.625 15 7.625C18.5901 7.625 21.5 10.5629 21.5 14.1875C21.5 14.1992 21.4965 14.2098 21.4965 14.2215C21.5029 19.2078 22.8493 21.9242 24.0808 23.5625H5.91918ZM15 32C17.0498 32 18.7125 30.3213 18.7125 28.25H11.2875C11.2875 30.3213 12.9502 32 15 32Z" fill="#FEF7FF" />
    </svg>
    );
}

export const MenuBurgerLogo = () => {
    return (
        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.66667V0H24V2.66667H0ZM0 9.33333H24V6.66667H0V9.33333ZM0 16H24V13.3333H0V16Z" fill="#FEF7FF" />
        </svg>
    );
}

export const CloseButton = () => {
    return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.80002 11.0001L0.000237369 19.8L2.20022 22L11 13.2001L19.7998 22L21.9998 19.8L13.2 11.0001L22 2.19999L19.8001 0L11 8.80012L2.19998 2.37371e-05L0 2.20002L8.80002 11.0001Z"
            fill="white" />
    </svg>);
}

export const TradingViewLogo = () => {
    return (<svg width="56" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0H0V6H5V13.5H11V0Z" fill="#793AFF" />
        <path d="M14 13.5L20 0H26L20 13.5H14Z" fill="#793AFF" />
        <path d="M14 5C15.1045 5 16 4.10457 16 3C16 1.89543 15.1045 1 14 1C12.8955 1 12 1.89543 12 3C12 4.10457 12.8955 5 14 5Z" fill="#793AFF" />
    </svg>
    )
}