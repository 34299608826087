import React, { useState, ChangeEvent } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import styles from '../Auth.module.scss';
import { setNewPassword } from "../../api/auth.api";

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [ispasswordSent, setIspasswordSent] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const sendPassword = async () => {
        try {
            await setNewPassword(password);
            setIspasswordSent(true);
            setError(null);
            navigate('/auth/login');
        } catch (error) {
            setError('Ошибка при сбросе пароля');
        }
    };

    return (
        <main className={`${styles.background} ${styles.registrationBackground}`}>
            <div className={styles.block}>
                <img src={require('../../assets/logo.png')} className={styles.logo} alt="logo" />
                <section className={`${styles['block-main']} ${styles['passwordRecovery-main']}`}>
                    <h1 className={styles['block-main-header']}>Восстановить пароль</h1>

                    <div className={styles.errorContainer}>
                        {error && <div className={styles.error}>{error}</div>}
                    </div>

                    <div className={styles['block-main-inputs']}>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="mail" className={styles['block-main-inputs-details_label']}><LockOutlined/></label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Новый пароль"
                                className={styles['block-main-inputs-details_input']}
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                    </div>

                    <div className={`${styles['block-main-container']} ${styles['center-align']}`}>
                        <button
                            type="button"
                            className={`${styles['button-light']} ${styles.button} ${styles['reset-button']}`}
                            onClick={sendPassword}
                        >
                            Сохранить
                        </button>
                    </div>

                </section>
            </div>
        </main>
    );
}

export default ResetPassword;
