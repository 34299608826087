import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Login from "./components/Login";
import Registration from "./components/Registration";
import PasswordRecovery from "./components/PasswordRecovery";
import ResetPassword from './components/ResetPassword';

interface AuthRouterProps {
    onAuthChange: () => void;
}

const AuthRouter: React.FC<AuthRouterProps> = ({ onAuthChange }) => {
    return (
        <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="registration" element={<Registration/>}/>
            <Route path="password-recovery" element={<PasswordRecovery/>}/>
            <Route path="reset-password" element={<ResetPassword/>}/>
            <Route path="*" element={<Login />} />
        </Routes>
    );
}

export default AuthRouter;
