import styles from "../Payment.module.scss";
import { Radio } from "antd";
import { Balance, PaymentActionEnum, PaymentOptionEnum, Promo } from "../../../models";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { deductBalance, formatNumber, getBalance, getCurrencySymbol, refillBalance } from "../../../../api/auth.api";
import { useUser } from "../../../useUser";

export const PaymentAction = ({ actionType, selectedOption }: { actionType: PaymentActionEnum, selectedOption: PaymentOptionEnum }) => {
    const [promo, setPromo] = useState<{ sum: number, bonus: number }>(Promo[0]);
    const [paymentAction, setPaymentAction] = useState<PaymentActionEnum>(actionType);
    const [deductionAmount, setDeductionAmount] = useState<number>(0);
    const [balance, setBalance] = useState<Balance>();
    const [promoCode, setPromoCode] = useState<string>('');
    const user = useUser();
    const navigate = useNavigate();

    const refill = async () => {
        try {
            const response = await refillBalance(selectedOption, promo.sum, +promoCode);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const deduct = async () => {
        try {
            const response = await deductBalance(selectedOption, deductionAmount);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const data = await getBalance();
                setBalance(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchBalance();
    }, []);

    const action = () => {
        if (actionType === PaymentActionEnum.Withdraw) {
            return (
                <div className={styles["payment-action-page"]}>
                    <main className={styles["payment-action-main"]}>
                        <label htmlFor="sum" style={{ display: "block" }}>Сумма</label>
                        <input id="sum" className={styles["sum-input"]} onChange={(e) => setDeductionAmount(Number(e.target.value))}
                        />
                        <div className={styles["balance-details"]}>
                            <p>Ваш баланс: {formatNumber(balance?.balance || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}</p>
                            <p>Заморожено: {formatNumber(balance?.freezed || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}</p>
                            <p>Доступно к выводу: {formatNumber(balance?.demo_balance || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}</p>
                        </div>
                    </main>
                    <footer className={styles["payment-action-footer"]}>
                        <button className={styles["payment-action-footer-button"]} onClick={deduct} disabled={deductionAmount < 5000}>Вывести</button>
                        <div className={styles["payment-action-footer-text"]}>
                            <p>Минимальная сумма пополнения: 5 000 ₽</p>
                            <p>Время зачисления: ~ 2 дня</p>
                        </div>
                    </footer>
                </div>
            );
        }
        return (
            <div className={styles["payment-action-page"]}>
                <main className={styles["payment-action-main"]}>
                    <div className={styles["payment-action-main-details"]}>
                        <div className={styles["payment-action-main-details-item"]}>
                            <p>Сумма</p>
                            <div className={styles["details-input"]}><p>{promo.sum}</p><p>&#36;</p></div>
                        </div>
                        <p className={styles["is-equal"]}>=</p>
                        <div className={styles["payment-action-main-details-item"]}>
                            <p>Сумма с бонусом</p>
                            <div className={styles["details-input"]}><p>{promo.sum + promo.sum * promo.bonus / 100}</p><p>&#36;</p></div>
                        </div>
                    </div>
                    <div className={styles["promo-group"]}>
                        <Radio.Group value={promo} onChange={(e) => setPromo(e.target.value)} buttonStyle="solid">
                            {Promo.map((item, index) => (
                                <Radio.Button key={index} value={item} className={styles["radio-button"]}>
                                    {item.sum}&#36;   <span className={styles["promo-bonus"]}>+{item.bonus}%</span>
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                    <p>Выберите бонус к пополнению либо введите имеющийся у вас промокод</p>
                    <div className={styles["terms-and-conditions"]}>
                        <p>Условия использования бонуса</p>
                        <a className="my-anchor-element">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#555555" />
                                <path d="M6.98413 5.71429H7.49206L9.01587 6.67429V12.5714H6.98413V5.71429ZM6.85714 3.47886V3.31429C6.85714 2.752 7.09841 2.28571 7.94921 2.28571H8.05079C8.90159 2.28571 9.14286 2.752 9.14286 3.31429V3.47886C9.14286 4.04114 8.90159 4.50743 8.05079 4.50743H7.94921C7.09841 4.50743 6.85714 4.04114 6.85714 3.47886Z" fill="white" />
                            </svg>
                        </a>
                        <Tooltip anchorSelect=".my-anchor-element" place="bottom-start" style={{ width: '30%', borderRadius: "0 8px 8px 8px", backgroundColor: "#35333b" }}>
                            <p style={{ color: "#FEF7FF", fontFamily: "Jost" }}>Для того что бы вывести бонусный депозит, вам нужно сделать оборот равный: (Сумма бонуса)*100</p>
                            <p style={{ color: "red", marginTop: "2%", fontFamily: "Jost" }}>При выводе депозита, бонусы сгорают.</p>
                        </Tooltip>
                    </div>
                    <label htmlFor="promo" style={{ display: "block" }} >Промокод</label>
                    <input id="promo" className={styles["sum-input"]} value={promoCode} onChange={(e) => setPromoCode(e.target.value)}
                    />
                </main>
                <footer className={styles["payment-action-footer"]}>
                    <button className={styles["payment-action-footer-button"]} onClick={refill}>Пополнить</button>
                    <div className={styles["payment-action-footer-text"]}>
                        <p>Минимальная сумма вывода: 500 ₽</p>
                        <p>Время зачисления: ~ 1 час</p>
                    </div>
                </footer>
            </div>
        );
    };

    return (
        <div className={styles["payment-block"]}>
            <div className={styles["option-action-block"]}>
                <Radio.Group value={paymentAction} buttonStyle="solid" onChange={(e) => setPaymentAction(e.target.value)}>
                    <Radio.Button value={PaymentActionEnum.Refill} className={styles["payment-action"]} onClick={() => navigate('/main/paymentRefill')}>Пополнение</Radio.Button>
                    <Radio.Button value={PaymentActionEnum.Withdraw} className={styles["payment-action"]} onClick={() => navigate('/main/paymentWithdraw')}>Вывод</Radio.Button>
                </Radio.Group>
            </div>
            {action()}
        </div>
    );
};
