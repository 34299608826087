import { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import styles from "./historyOfWorks.module.scss";
import { formatDate, formatNumber, getCurrencySymbol, getUserPredictions, getUserStates } from "../../../api/auth.api";
import { useUser } from "../../useUser";
import { UserPrediction, UserStats } from "../../models";

export const HistoryOfWorks = () => {
    const user = useUser();
    const [monthStats, setMonthStats] = useState<UserStats>();
    const [predictions, setPredictions] = useState<UserPrediction[]>();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const data = await getUserStates();
                setMonthStats(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        const fetchPredictions = async () => {
            try {
                const data = await getUserPredictions();
                setPredictions(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        }

        fetchStats();
        fetchPredictions();
    }, []);

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.top}>
                <h4 className={styles.title}>Месячная прибыль</h4>
                <div className={styles.monthWork}>
                    <div className={`${styles.box} ${styles.monthProfit}`}>
                        <p className={styles.boxP}>Месячная прибыль</p>
                        <h2 className={styles.boxMoney}>
                            {formatNumber(monthStats?.monthly_profit || 0)}{" "}
                            {getCurrencySymbol(user.user?.profile.currency || "")}
                        </h2>
                    </div>
                    <div className={`${styles.box} ${styles.success}`}>
                        <p className={styles.boxP}>Успешных ордеров</p>
                        <h2 className={styles.boxMoney}>
                            {formatNumber(monthStats?.successful_orders || 0)}
                        </h2>
                    </div>
                    <div className={`${styles.box} ${styles.fail}`}>
                        <p className={styles.boxP}>Провальных ордеров</p>
                        <h2 className={styles.boxMoney}>
                            {formatNumber(monthStats?.failed_orders || 0)}
                        </h2>
                    </div>
                    <div className={`${styles.box} ${styles.hidden}`} style={{ backgroundColor: "#2B2930" }}>
                        <p className={styles.boxP}>Общий оборот</p>
                        <h2 className={styles.boxMoney}>
                            {formatNumber(monthStats?.total_turnover || 0)}{" "}
                            {getCurrencySymbol(user.user?.profile.currency || "")}
                        </h2>
                    </div>
                    <div className={`${styles.box} ${styles.hidden}`} style={{ backgroundColor: "#2B2930" }}>
                        <p className={styles.boxP}>% проходимости</p>
                        <h2 className={styles.boxMoney}>
                            {formatNumber(monthStats?.success_rate || 0)}%
                        </h2>
                    </div>
                </div>
            </div>
            <h4 className={styles.title2}>История сделок</h4>
            <div className={styles.tables}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={`${styles.tableTh} ${styles.tableThFirst}`}>Пара</th>
                            <th className={styles.tableTh}>Сумма сделки</th>
                            <th className={styles.tableTh}>Время открытия</th>
                            <th className={styles.tableTh}>Точка открытия</th>
                            <th className={styles.tableTh}>Точка закрытия</th>
                            <th className={styles.tableTh}>Ставка</th>
                            <th className={`${styles.tableTh} ${styles.tableThLast}`}>Выигрыш / проигрыш</th>
                        </tr>
                    </thead>
                    <tbody>
                        {predictions?.map((prediction, index) => (
                            <tr key={index}
                                className={prediction.is_up ? styles.winRow : styles.loseRow}>
                                <td>{prediction.symbol}</td>
                                <td>{formatNumber(prediction.amount || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}</td>
                                <td>{formatDate(prediction.time_of_open)}</td>
                                <td>{formatNumber(prediction.dot_of_open)}</td>
                                <td>{formatNumber(prediction.dof_of_close)}</td>
                                <td><p className={prediction.is_up ? styles.win : styles.lose}>{prediction.is_up ? "Выше" : "Ниже"}</p></td>
                                <td>{prediction.is_up ? "+" : "-"} {formatNumber(prediction.sum_of_win)} {getCurrencySymbol(user.user?.profile.currency || '')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
