import { useEffect, useState } from 'react';
import headerStyles from "../../Header/header.module.scss";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { DeductLogo, Logo, LogOutLogo, NotificationLogo, ProfileLogo, TradingViewLogo } from '../../../icones';
import { RatingPane } from '../../Header/Rating';
import { NotificationPane } from '../../Header/Notifications';
import axios from 'axios';
import { Select } from 'antd';

export const HeaderForTrade = ({ selectedTicker, setSelectedTicker }) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        isRatingPaneOpen: false,
        isNotificationPaneOpen: false,
        isSmallPaneOpen: false,
        paneWidth: '45%',
    });
    const [balance, setBalance] = useState({
        balance: 0,
        demo_balance: 0
    });
    const [activePage, setActivePage] = useState("profile");
    const [tickers, setTickers] = useState([]);

    const handleReFill = () => {
        axios
            .post(
                "https://oneoption.ru/api/v1/payments/refill",
                {
                    method: "MasterCard",
                    amount: 100000,
                    promo: "string",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handleGetBalance();
        axios
            .get("https://oneoption.ru/api/v1/options/tickers", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setTickers(res.data);
                setSelectedTicker(formatPair(res.data[0]) || null);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    const handleGetBalance = () => {
        axios.get("https://oneoption.ru/api/v1/payments/balance", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(res => {
                setBalance(res.data);
                console.log(res)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const formatPair = (pair) => {
        if (pair && typeof pair === 'string') {
            let m = pair.match(/^([A-Za-z]+)(USDT)$/i);
            if (m) {
                const formattedPair = `${m[1]} - ${m[2]}`;
                return formattedPair.toUpperCase();
            }
        }
        return pair;
    }



    return (
        <>
            <header className={headerStyles.header}>
                <div className={headerStyles.menu}>
                    <div className={styles.menuLeft}>
                        <div
                            className={headerStyles.logo}
                            onClick={() => {
                                setActivePage("tradeList");
                                navigate("/main/tradeList");
                            }}
                        >
                            <Logo />
                        </div>
                    </div>
                    <div className={styles.menuRight}>
                        <div className={styles.menuRightSelection}>
                            <Select
                                value={selectedTicker}
                                onChange={(value) => setSelectedTicker(formatPair(value))}
                                dropdownStyle={{
                                    backgroundColor: '#36343B',
                                }}
                                className={headerStyles.tickers}
                            >
                                {tickers.map((ticker) => (
                                    <Select.Option
                                        key={ticker}
                                        value={ticker}
                                        style={{
                                            backgroundColor: '#36343B',
                                            color: 'white',
                                        }}
                                    >
                                        {formatPair(ticker)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            className={styles.tradingViewLogo}
                            onClick={() => {
                                window.open("https://www.tradingview.com/", "_blank");
                            }}
                        >
                            <TradingViewLogo />
                        </div>
                        <div className={styles.refillButton} onClick={handleReFill}>
                            Пополнить
                        </div>
                        <div
                            className={headerStyles.buttonTops}
                            onClick={() => {
                                setState({
                                    isRatingPaneOpen: false,
                                    isNotificationPaneOpen: true,
                                    isSmallPaneOpen: false,
                                    paneWidth: '45%',
                                });
                            }}
                        >
                            <NotificationLogo />
                        </div>
                        <div
                            className={`${headerStyles.buttonTops} ${activePage === "profile"
                                ? headerStyles.active
                                : headerStyles.disabled
                                }`}
                            onClick={() => {
                                setActivePage("profile");
                                navigate("/main/profile");
                            }}
                        >
                            <ProfileLogo />
                        </div>
                        <div className={styles.rightSideHeader}>
                            <div>
                                <p className={styles.isDemo}>Демо-баланс</p>
                                <p className={styles.allAmount}>₽ {balance && balance.demo_balance}</p>
                            </div>
                        </div>
                        <div
                            className={headerStyles.buttonTops + " " + headerStyles.deduct}
                            onClick={() => {
                                localStorage.removeItem('token');
                                navigate('/auth/login');
                            }}
                        >
                            <LogOutLogo />
                        </div>
                    </div>
                </div>
                <RatingPane
                    isOpen={state.isRatingPaneOpen}
                    onClose={() => setState({ ...state, isRatingPaneOpen: false })}
                    paneWidth={state.paneWidth}
                />

                <NotificationPane
                    isOpen={state.isNotificationPaneOpen}
                    onClose={() => setState({ ...state, isNotificationPaneOpen: false })}
                    paneWidth={state.paneWidth}
                />
            </header>
        </>
    );
};
