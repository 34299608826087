import { useState, ChangeEvent, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Checkbox, Radio } from "antd";
import { Currency } from "../models";
import { registerUser, loginUser } from "../../api/auth.api";
import styles from "../Auth.module.scss";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function Registration() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currency, setCurrency] = useState<Currency>(Currency.USD);
    const [error, setError] = useState<string | null>(null);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);
    const handleCurrencyChange = (e: any) => setCurrency(e.target.value);
    const handleCheckboxChange = (e: CheckboxChangeEvent) => setIsCheckboxChecked(e.target.checked);
    const [user, setUser] = useState<any>(null);

    const login = async (em: string, pass: string) => {
        try {
            const { access_token } = await loginUser(em, pass);
            localStorage.setItem('token', access_token);
            navigate('/main/tradeList');
        } catch (error) {
            console.error(error);
        }
    };

    const loginByGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse as any),
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleRegisterAndLogin = async (us: any) => {
        if (!us.email || !us.id) {
            console.error("Email or ID is missing from Google OAuth response.");
            return;
        }

        try {
            await registerUser(us.email, us.id, Currency.USD);
        } catch (error) {
            console.error('Registration failed, attempting login:', error);
        }

        try {
            await login(us.email, us.id);
            navigate('/main/tradeList');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        if (user) {
            const accessToken = user.access_token;
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    handleRegisterAndLogin(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    const registerF = async () => {
        if (password !== confirmPassword) {
            setError("Пароли не совпадают");
            return;
        }

        try {
            await registerUser(email, password, currency);
            setError(null);
            const { access_token } = await loginUser(email, password);
            localStorage.setItem('token', access_token);
            navigate('/main/profile');
        } catch (error) {
            console.error('Registration failed:', error);
            setError('Ошибка регистрации');
        }
    };

    return (
        <main className={`${styles.background} ${styles.registrationBackground}`}>
            <div className={styles.block}>
                <img src={require('../../assets/logo.png')} className={styles.logo} alt="logo" />
                <section className={styles['block-main']}>
                    <header className={styles['block-main-header']}>
                        <h1>Регистрация</h1>
                        <h3>Просто, Уверенно, Прибыльно</h3>
                    </header>

                    <div className={styles['block-main-inputs']}>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="mail" className={styles['block-main-inputs-details_label']}><MailOutlined /></label>
                            <input
                                type="email"
                                id="mail"
                                placeholder="Почта"
                                className={styles['block-main-inputs-details_input']}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="password" className={styles['block-main-inputs-details_label']}><LockOutlined /></label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Пароль"
                                className={styles['block-main-inputs-details_input']}
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="checkPassword" className={styles['block-main-inputs-details_label']}><LockOutlined /></label>
                            <input
                                type="password"
                                id="checkPassword"
                                placeholder="Подтвердите пароль"
                                className={styles['block-main-inputs-details_input']}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                        </div>
                    </div>

                    <Radio.Group value={currency} onChange={handleCurrencyChange} buttonStyle="solid">
                        <Radio.Button value={Currency.USD} className={styles['radio-button']}>&#36; USD</Radio.Button>
                        <Radio.Button value={Currency.RUB} className={styles['radio-button']}>&#8381; RUB</Radio.Button>
                        <Radio.Button value={Currency.EUR} className={styles['radio-button']}>&#8364; EUR</Radio.Button>
                    </Radio.Group>

                    <div className={styles.errorContainer}>
                        {error && <div className={styles.error}>{error}</div>}
                    </div>

                    <div className={styles['block-main-container']}>
                        <Checkbox className={styles['block-main-checkbox']}
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}>Я подтверждаю, что я старше 18 лет и я принимаю</Checkbox>
                        <Link to="/" className={styles.link}>Пользовательское соглашение</Link>
                    </div>

                    <div className={`${styles['block-main-buttons']} ${styles['block-main-container']}`}>
                        <button
                            type="button"
                            className={`${styles['button-light']} ${styles.button}`}
                            onClick={registerF}
                            disabled={!isCheckboxChecked}
                        >
                            Зарегистрироваться
                        </button>
                        <button type="button"
                            className={`${styles['button-transparent']} ${styles.button}`} onClick={() => loginByGoogle()}>Регистрация через Google
                        </button>
                    </div>
                    <div className={styles['block-main-state']}>
                        <span>Уже есть аккаунт? </span>
                        <Link to='/auth/login' className={styles.link}>Войти</Link>
                    </div>
                </section>
            </div>
        </main>
    );
}

export default Registration;
