export enum Currency{
    'USD', "RUB", "EUR"
}

export interface UserData {
    email: string;
    password: string;
}

export interface RegisterData extends UserData {
    currency: Currency;
}

export interface PasswordData {
    oldPassword: string;
    newPassword: string;
}

export interface AuthResponse {
    token: string;
    user: {
        id: string;
        name: string;
        email: string;
    };
}
