// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Safety_formContainer__neMFr {
  background-color: #211f26;
  border-radius: 20px;
  color: #FEF7FF;
  font-family: Jost;
  padding: 20px;
  position: relative;
}

.Safety_formGroupContainer__\\+kk8c {
  margin-bottom: 4%;
}

.Safety_safetyHeader__V86kT {
  margin-bottom: 20px;
  text-align: start;
}

.Safety_formGroup__YGxDT {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

.Safety_inputField__E3vBL {
  flex: 3 1;
  border: none;
  background-color: #2B2930;
  border-radius: 8px;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
  position: relative;
}

.Safety_saveButton__P5NCJ {
  background-color: #652CDE;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  right: 3%;
  bottom: 4%;
}

.Safety_wrapper__jj3Bp {
  margin-bottom: 5%;
}`, "",{"version":3,"sources":["webpack://./src/main/components/Profile/Safety/Safety.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,iBAAA;AAGF;;AADA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAIF;;AADA;EACE,SAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AAIF;;AADA;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AAIF;;AAFA;EACE,iBAAA;AAKF","sourcesContent":[".formContainer {\n  background-color: #211f26;\n  border-radius: 20px;\n  color: #FEF7FF;\n  font-family: Jost;\n  padding: 20px;\n  position: relative;\n}\n.formGroupContainer{\n  margin-bottom: 4%;\n}\n.safetyHeader {\n  margin-bottom: 20px;\n  text-align: start;\n}\n.formGroup{\n  display: flex;\n  flex-direction: column;\n  margin-bottom:2% ;\n}\n\n.inputField {\n  flex: 3;\n  border: none;\n  background-color: #2B2930;\n  border-radius: 8px;\n  color: #fff;\n  padding: 10px;\n  margin-right: 10px;\n  position: relative;\n}\n\n.saveButton {\n  background-color: #652CDE;\n  border: none;\n  color: #fff;\n  padding: 10px 20px;\n  border-radius: 5px;\n  cursor: pointer;\n  margin-top: 20px;\n  position: absolute;\n  right: 3%;\n  bottom: 4%;\n}\n.wrapper{\n  margin-bottom: 5%;\n}\n\n\n@media (max-width: 834px) {\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `Safety_formContainer__neMFr`,
	"formGroupContainer": `Safety_formGroupContainer__+kk8c`,
	"safetyHeader": `Safety_safetyHeader__V86kT`,
	"formGroup": `Safety_formGroup__YGxDT`,
	"inputField": `Safety_inputField__E3vBL`,
	"saveButton": `Safety_saveButton__P5NCJ`,
	"wrapper": `Safety_wrapper__jj3Bp`
};
export default ___CSS_LOADER_EXPORT___;
