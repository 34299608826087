import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserProvider } from "./UserContext";
import { Loading } from "./loading/Loading";
import "./i18n/i18n";
import axios from "axios";

const LandingPage = React.lazy(() => import("./landing/Landing"));
const FAQComponent = React.lazy(() => import("./landing/questions/questions"));
const AuthRouter = React.lazy(() => import("./auth/AuthRouter"));
const Main = React.lazy(() => import("./main/Main"));

axios.defaults.baseURL = "https://api.optimax.vip/api/v1";

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [i18nReady, setI18nReady] = useState(false);

    useEffect(() => {
        import("./i18n/i18n").then(() => setI18nReady(true));
    }, []);

    return (
        <BrowserRouter>
            <UserProvider>
                <AuthHandler setIsAuthenticated={setIsAuthenticated} />
                {(!i18nReady || isAuthenticated === null) ? (
                    <Loading />
                ) : (
                    <React.Suspense fallback={<Loading />}>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/landingPage" element={<LandingPage />} />
                            <Route path="/questions" element={<FAQComponent />} />
                            <Route
                                path="/auth/*"
                                element={
                                    isAuthenticated ? (
                                        <Navigate to="/main/tradeList" replace />
                                    ) : (
                                        <AuthRouter onAuthChange={() => setIsAuthenticated(true)} />
                                    )
                                }
                            />

                            {/* Private Routes */}
                            <Route
                                path="/main/*"
                                element={
                                    isAuthenticated ? (
                                        <Main />
                                    ) : (
                                        <Navigate to="/auth/login" replace />
                                    )
                                }
                            />

                            {/* Catch-All Route */}
                            <Route
                                path="*"
                                element={<Navigate to="/landingPage" replace />}
                            />
                        </Routes>
                    </React.Suspense>
                )}
            </UserProvider>
        </BrowserRouter>
    );
};

const AuthHandler: React.FC<{ setIsAuthenticated: (auth: boolean) => void }> = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkAuthStatus = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setIsAuthenticated(false);
                return;
            }

            try {
                await axios.get("/auth/verify-token", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setIsAuthenticated(true);

                // Only redirect if already on an auth route
                if (location.pathname.startsWith("/auth")) {
                    navigate("/main/tradeList", { replace: true });
                }
            } catch {
                setIsAuthenticated(false);
            }
        };

        checkAuthStatus();
    }, [navigate, location.pathname, setIsAuthenticated]);

    return null;
};

export default App;
