import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthRouter from "./auth/AuthRouter";
import Main from "./main/Main";
import { UserProvider } from './UserContext';
import { Loading } from './loading/Loading';

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    const checkAuthStatus = () => {
        const token = localStorage.getItem('token');
        setIsAuthenticated(token !== null);
    };

    useEffect(() => {
        checkAuthStatus();
        window.addEventListener("storage", checkAuthStatus);

        return () => {
            window.removeEventListener("storage", checkAuthStatus);
        };
    }, []);

    if (isAuthenticated === null) {
        return <Loading />;
    }

    return (
        <BrowserRouter>
            <UserProvider>
                <Routes>
                    <Route path="/auth/*" element={<AuthRouter onAuthChange={checkAuthStatus} />} />
                    <Route 
                        path="/main/*" 
                        element={
                            isAuthenticated ? <Main /> : <Navigate to="/auth/login" />
                        } 
                    />
                    <Route path="*" element={<Navigate to={isAuthenticated ? "/main/profile" : "/auth/login"} />} />
                </Routes>
            </UserProvider>
        </BrowserRouter>
    );
};

export default App;
