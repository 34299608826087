import React, { useState, useEffect, ChangeEvent } from 'react';
import styles from './UserInfo.module.scss';
import { Profile as ProfileType } from "../../../models";
import axios from 'axios';
import { updateProfile } from '../../../../api/auth.api';
import { useUser } from '../../../useUser';

interface FormData {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthDate: string;
}

interface IsEditing {
    [key: string]: boolean;
}

export const UserInfo: React.FC<{ profileInfo: ProfileType | null }> = ({ }) => {
    const user = useUser();
    const [formData, setFormData] = useState<FormData>({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
    });

    const [isEditing, setIsEditing] = useState<IsEditing>({
        username: false,
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        birthDate: false,
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user.user) {
            setFormData({
                username: user.user.profile.nickname,
                firstName: user.user.profile.first_name,
                lastName: user.user.profile.last_name,
                email: user.user.email,
                phone: user.user.profile.phone_number,
                birthDate: user.user.profile.date_of_birth,
            });
            setIsLoading(false);
        }
        console.log(user.user)
    }, []);

    const handleUpdateProfile = async () => {
        const requestBody = {
            nickname: formData.username,
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone_number: formData.phone,
            date_of_birth: formData.birthDate,
            currency: user.user?.profile.currency
        };

        console.log(requestBody);

        try {
            const response = await updateProfile(requestBody);
            console.log('Profile updated successfully:', response);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const getRussianLabel = (field: keyof FormData): string => {
        switch (field) {
            case 'username':
                return 'Никнейм';
            case 'firstName':
                return 'Имя';
            case 'lastName':
                return 'Фамилия';
            case 'email':
                return 'Email';
            case 'phone':
                return 'Номер телефона';
            case 'birthDate':
                return 'Дата рождения';
            default:
                return '';
        }
    };

    const handleEdit = (field: keyof FormData) => {
        setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));
        if (!isEditing[field]) {
            const input = document.getElementById(field);
            if (input) {
                input.focus();
            }
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        console.log(formData)
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.formContainer}>
            <h3>Личные данные</h3>
            <div className={styles.wrapper}>
                {Object.keys(formData).map((field) => (
                    <div key={field} className={styles.formGroup}>
                        <label>{getRussianLabel(field as keyof FormData)}</label>
                        <input
                            type="text"
                            name={field}
                            id={field}
                            value={formData[field as keyof FormData]}
                            onChange={handleChange}
                            readOnly={!isEditing[field]}
                            className={`${styles.inputField} ${isEditing[field] ? styles.editable : styles.inputField}`}
                        />
                        <button
                            type="button"
                            onClick={() => handleEdit(field as keyof FormData)}
                            className={styles.editButton}
                        >
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 5H3C2.46957 5 1.96086 5.21071 1.58579 5.58579C1.21071 5.96086 1 6.46957 1 7V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H12C12.5304 18 13.0391 17.7893 13.4142 17.4142C13.7893 17.0391 14 16.5304 14 16V15"
                                    stroke="#FEF7FF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M13 3.00011L16 6.00011M17.385 4.58511C17.7788 4.19126 18.0001 3.65709 18.0001 3.10011C18.0001 2.54312 17.7788 2.00895 17.385 1.61511C16.9912 1.22126 16.457 1 15.9 1C15.343 1 14.8088 1.22126 14.415 1.61511L6 10.0001V13.0001H9L17.385 4.58511Z"
                                    stroke="#FEF7FF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
            <button type="button" onClick={handleUpdateProfile} className={styles.saveButton}>
                Сохранить
            </button>
        </div>
    );
};
