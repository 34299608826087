import React, { useEffect, useState } from 'react';
import headerStyles from "./header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { DeductLogo, HelpLogo, HistoryLogo, LeaderBoardLogo, Logo, LogOutLogo, MenuBurgerLogo, MonthHistoryLogo, NotificationLogo, ProfileLogo, RefillLogo, TelegramLogo } from '../../icones';
import { RatingPane } from './Rating';
import { NotificationPane } from './Notifications';
import { SmallHeaderPane } from './SmallHeader';
import axios from 'axios';

export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        isRatingPaneOpen: false,
        isNotificationPaneOpen: false,
        isSmallPaneOpen: false,
        paneWidth: '45%'
    });
    const [activePage, setActivePage] = useState(location.pathname.split("/")[2] || "");
    useEffect(() => {
        const currentPath = location.pathname.split("/")[2];
        setActivePage(currentPath || "");

    }, [location]);

    useEffect(() => {
        axios.get(`https://oneoption.ru/api/v1/auth/verify-token`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: 'application/json'
            }
        }).then(res => {
            console.log(res.data);
        }).catch(err => {
            axios.post(`https://oneoption.ru/api/v1/auth/refresh`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: 'application/json'
                }
            }).then(res => {
                localStorage.setItem("token", res.data.access_token);
            }).catch(err => {
                console.log(err);
            })
        })
    })

    return (<>
        <header className={headerStyles.header}>
            <div className={headerStyles.menu}>
                <div className={headerStyles.menuLeft}>
                    <div className={headerStyles.logo}
                        onClick={() => { setActivePage("tradeList"); navigate("/main/tradeList") }}>
                        <Logo />
                    </div>
                    <div className={headerStyles.buttonTops} onClick={() => {
                        setState({ isRatingPaneOpen: true, isNotificationPaneOpen: false, isSmallPaneOpen: false, paneWidth: '45%' });
                    }}>
                        <LeaderBoardLogo />
                    </div>
                    <div className={`${headerStyles.buttonTops} ${activePage == "profile" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage("profile"); navigate("/main/profile") }}>
                        <ProfileLogo />
                    </div>

                    <div className={`${headerStyles.buttonTops} ${activePage == "paymentRefill" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage("paymentRefill"); navigate("/main/paymentRefill") }}>
                        <RefillLogo />
                    </div>
                    <div className={`${headerStyles.buttonTops} ${activePage == "paymentWithdraw" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage("paymentWithdraw"); navigate('/main/paymentWithdraw') }}>
                        <DeductLogo />
                    </div>
                    <div className={`${headerStyles.buttonTops} ${activePage == "historyofworks" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage("historyofworks"); navigate('/main/historyofworks') }}>
                        <MonthHistoryLogo />
                    </div>
                    <div className={`${headerStyles.buttonTops} ${activePage == "historytransaction" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage('historytransaction'); navigate('/main/historytransaction') }}>
                        <HistoryLogo />
                    </div>
                </div>
                <div className={headerStyles.menuRight}>
                    <div className={`${headerStyles.buttonTops} ${activePage == "help" ? headerStyles.active : headerStyles.disabled}`} onClick={() => { setActivePage("help"); navigate('/main/help') }}>
                        <HelpLogo />
                    </div>
                    <div className={headerStyles.buttonTops} onClick={() => {
                        setState({ isRatingPaneOpen: false, isNotificationPaneOpen: true, isSmallPaneOpen: false, paneWidth: '45%' });
                    }}>
                        <NotificationLogo />
                    </div>
                    <a href="https://web.telegram.org/a/" target="_blank" className={headerStyles.buttonTops}>
                        <TelegramLogo />
                    </a>
                    <div className={headerStyles.buttonTops} onClick={() => {
                        localStorage.removeItem('token');
                        navigate('/auth/login');
                    }}>
                        <LogOutLogo />
                    </div>
                </div>
            </div>
            <RatingPane
                isOpen={state.isRatingPaneOpen}
                onClose={() => setState({ ...state, isRatingPaneOpen: false })}
                paneWidth={state.paneWidth}
            />

            <NotificationPane
                isOpen={state.isNotificationPaneOpen}
                onClose={() => setState({ ...state, isNotificationPaneOpen: false })}
                paneWidth={state.paneWidth}
            />
        </header>
        <header className={headerStyles.smallHeader}>
            <div
                className={headerStyles.buttonTops}
                onClick={() => {
                    setState({ ...state, isSmallPaneOpen: true });
                }}
            >
                <MenuBurgerLogo />
            </div>
            <div
                className={headerStyles.buttonTops}
                onClick={() =>
                    setState({
                        isRatingPaneOpen: false,
                        isNotificationPaneOpen: true,
                        isSmallPaneOpen: false,
                        paneWidth: '100%'
                    })
                }
            >
                <NotificationLogo />
            </div>
        </header>
        <SmallHeaderPane
            isOpen={state.isSmallPaneOpen}
            onClose={() =>
                setState({ ...state, isSmallPaneOpen: false })
            }
        />
    </>
    )
}