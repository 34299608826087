// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Helper_helper-container__S2UGy {
  background-color: #141218;
  display: grid;
  grid-template-columns: auto auto auto;
  color: white;
  gap: 1%;
  padding: 2%;
}

.Helper_item__NCr3P {
  background-color: #211F26;
  border-radius: 10px;
  padding: 5% 5% 20%;
  margin: 1%;
}
.Helper_item-header__zaZ0m {
  margin-bottom: 6%;
  font-family: "GetVoIP Grotesque", sans-serif;
}

@media (max-width: 834px) {
  .Helper_helper-container__S2UGy {
    display: block;
  }
  .Helper_item__NCr3P {
    padding: 3%;
  }
  .Helper_item-header__zaZ0m {
    margin-bottom: 2%;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/components/Helper/Helper.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,qCAAA;EACA,YAAA;EACA,OAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AACF;AACE;EACE,iBAAA;EACA,4CAAA;AACJ;;AAIA;EACE;IACE,cAAA;EADF;EAGA;IACE,WAAA;EADF;EAEE;IACE,iBAAA;EAAJ;AACF","sourcesContent":[".helper-container{\n  background-color: #141218;\n  display: grid;\n  grid-template-columns: auto auto auto;\n  color: white;\n  gap: 1%;\n  padding: 2%;\n}\n\n.item{\n  background-color: #211F26;\n  border-radius: 10px;\n  padding: 5% 5% 20%;\n  margin: 1%;\n\n  &-header{\n    margin-bottom: 6%;\n    font-family: 'GetVoIP Grotesque', sans-serif;\n  }\n}\n\n\n@media (max-width: 834px) {\n  .helper-container{\n    display: block;\n  }\n  .item{\n    padding: 3% ;\n    &-header{\n      margin-bottom: 2%;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helper-container": `Helper_helper-container__S2UGy`,
	"item": `Helper_item__NCr3P`,
	"item-header": `Helper_item-header__zaZ0m`
};
export default ___CSS_LOADER_EXPORT___;
