import axiosInstance from './axios';
import { UserData, RegisterData, AuthResponse, Currency } from '../auth/models';
import axios from "axios";
import { PaymentOptionEnum } from '../main/models';

export const Axios = axios.create({
    baseURL: "https://oneoption.ru/",
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
})

//Auth
export const loginUser = async (email: string, password: string) => {
    const requestBody = { email, password };
    try {
        const response = await Axios.post('/api/v1/auth/login', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const response = await Axios.post('/api/v1/auth/refresh');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const loginGoogle = async () => {
    try {
        const response = await Axios.get('/api/v1/google/login');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const registerUser = async (email: string, password: string, currency: Currency) => {
    const requestBody = { email, password, currency: Currency[currency] };
    try {
        const response = await Axios.post('/api/v1/register', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendEmailforPassword = async (email: string) => {
    const requestBody = { email };
    try {
        const response = await Axios.post('/api/v1/register/forgot-password', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const setNewPassword = async (password: string) => {
    const requestBody = { password };
    const token = localStorage.getItem('password_access_token');
    try {
        const response = await Axios.post(`/api/v1/register/forgot-password/${token}`, requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
}

//Default
export const getBalance = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.get('/api/v1/payments/balance', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const refillBalance = async (method: PaymentOptionEnum, amount: number, bonus: number) => {
    const requestBody = { method: PaymentOptionEnum[method], amount, promo: bonus };
    try {
        const response = await Axios.post('/api/v1/payments/refill', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deductBalance = async (method: PaymentOptionEnum, amount: number) => {
    const requestBody = { method: PaymentOptionEnum[method], amount };
    try {
        const response = await Axios.post('/api/v1/payments/deduct', requestBody);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getLeaderBoard = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await Axios.get('/api/v1/trading/transactions/leaderboard', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getUserStates = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await Axios.get('/api/v1/trading/transactions/user/stats', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getUserPredictions = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await Axios.get('/api/v1/trading/transactions/user/predictions', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTransitionsHistory = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.get('/api/v1/trading/transactions/history', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch transitions history:", error);
        throw error;
    }
};


//Profile
export const getProfile = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.get('/api/v1/profile/me', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const updateProfile = async (profileData: any) => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.patch(`/api/v1/profile/update-profile`, profileData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating profile:', error);
        throw error;
    }
};
export const resetPassword = async (newPassword: string) => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.post(
            '/api/v1/profile/reset-password',
            { password: newPassword },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};


//Verification
export const checkVerification = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await Axios.get('/api/v1/verification/check_verification', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const postVerificationData = async (requestBody: Record<string, any>) => {
    const token = localStorage.getItem('token');
    try {
        const response = await Axios.post('/api/v1/verification/send', requestBody, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending verification:', error);
        throw error;
    }
};
export const postVerificationPicture = async () => { }
export const requestEmailVerification = async () => { }



//general functions
export const formatNumber = (number: number): string => {
    const [integerPart, decimalPart] = number.toFixed(2).split('.');

    const formattedInteger = new Intl.NumberFormat('en-US', {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(Number(integerPart)).replace(/,/g, ' ');

    const formattedDecimal = decimalPart ? `,${decimalPart}` : '';

    return formattedInteger + formattedDecimal;
};

export const getCurrencySymbol = (currencyCode: string): string => {
    const currencySymbols: Record<string, string> = {
        USD: "$",
        EUR: "€",
        RUB: "₽",
    };

    return currencySymbols[currencyCode] || currencyCode;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

