import React, { useState, ChangeEvent } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { MailOutlined } from "@ant-design/icons";
import styles from '../Auth.module.scss';
import { sendEmailforPassword } from "../../api/auth.api";

function PasswordRecovery() {
    const [email, setEmail] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const sendEmail = async () => {
        try {
            const { password_access_token } = await sendEmailforPassword(email);
            localStorage.setItem('password_access_token', password_access_token);
            setIsEmailSent(true);
            setError(null);
        } catch (error) {
            console.error('Error sending password reset email:', error);
            setError('Ошибка при отправке электронной почты для сброса пароля');
        }
    };

    return (
        <main className={`${styles.background} ${styles.registrationBackground}`}>
            <div className={styles.block}>
                <img src={require('../../assets/logo.png')} className={styles.logo} alt="logo"/>
                <section className={`${styles['block-main']} ${styles['passwordRecovery-main']}`}>
                    <h1 className={styles['block-main-header']}>Восстановить пароль</h1>

                    <div className={styles.errorContainer}>
                        {error && <div className={styles.error}>{error}</div>}
                    </div>

                    <div className={styles['block-main-inputs']}>
                        <div className={styles['block-main-inputs-details']}>
                            <label htmlFor="mail" className={styles['block-main-inputs-details_label']}><MailOutlined/></label>
                            <input
                                type="email"
                                id="mail"
                                placeholder="Почта"
                                className={styles['block-main-inputs-details_input']}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                    </div>

                    {!isEmailSent ? (
                        <div className={`${styles['block-main-container']} ${styles['center-align']}`}>
                            <button
                                type="button"
                                className={`${styles['button-light']} ${styles.button} ${styles['reset-button']}`}
                                onClick={sendEmail}
                            >
                                Сброс
                            </button>
                            <div className={styles['block-main-state']}>
                                <span>Вы помните свой пароль? </span>
                                <Link to='/auth/login' className={styles.link}>Войти</Link>
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles['block-main-container']} ${styles['center-align']}`}>
                            <span>Отправлено электронное письмо для сброса пароля!</span>
                            <button
                                type="button"
                                className={`${styles['button-light']} ${styles.button} ${styles['reset-button']}`}
                                onClick={() => navigate('/auth/login')}
                            >
                                Вернуться
                            </button>
                        </div>
                    )}
                </section>
            </div>
        </main>
    );
}

export default PasswordRecovery;
