import styles from "./Alert.module.scss";
import React from "react";
import { Button, Upload } from "antd";

export const Alert = () => {
    return (
        <div className={styles['alert-block']}>
            <div>
                <h2 className={styles['alert-block-header']}>Внимание !</h2>
                <p>Сумма вывода превышает допустимые лимиты с вашим уровнем верификации.</p>
                <p>Пожалуйста, предоставьте ваше фото с паспортом в руках и бумагой на которой будет написано “One Option” и сегодняшняя дата, для полной верификации вашего аккаунта.</p>
            </div>
            <Upload className={styles['alert-block-uploader']}>
                <Button icon={
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5 7.5V28.5H7.5V7.5H28.5ZM28.5 4.5H7.5C5.85 4.5 4.5 5.85 4.5 7.5V28.5C4.5 30.15 5.85 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5ZM21.21 17.79L16.71 23.595L13.5 19.71L9 25.5H27L21.21 17.79Z" fill="white"/>
                    </svg>
                } style={{backgroundColor:"#793AFF", border:"none"}}>
                    Загрузить фото
                </Button>
            </Upload>
        </div>
    );
};
