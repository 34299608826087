import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import slidepaneStyles from "./slidepane.module.scss";
import { CloseButton } from "../../icones";
import { useUser } from "../../useUser";
import { Payment } from "../../models";
import { formatNumber, getCurrencySymbol, getTransitionsHistory } from "../../../api/auth.api";

interface NotificationPaneProps {
    isOpen: boolean;
    onClose: () => void;
    paneWidth: string;
}

export const NotificationPane: React.FC<NotificationPaneProps> = ({
    isOpen,
    onClose,
    paneWidth
}) => {
    const user = useUser();
    const [refills, setRefills] = useState<Payment[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const data = await getTransitionsHistory();
                setPayments(data);
            } catch (error) {
                console.error("Failed to fetch payment history:", error);
            }
        };

        fetchHistory();
        const sortedRefills = payments.filter(payment => payment.is_refill);

        setRefills(sortedRefills);
    }, []);

    return (
        <SlidingPane
            className={slidepaneStyles.slidePane}
            isOpen={isOpen}
            width={paneWidth}
            onRequestClose={onClose}
            hideHeader={true}
        >
            <header className={slidepaneStyles.notificationsHeader}>
                <div className={slidepaneStyles.slidingPaneHeader}>
                    <h2>Уведомления</h2>
                    <button onClick={onClose} className={slidepaneStyles.backIcon}>
                        <CloseButton />
                    </button>
                </div>
            </header>
            <div className={slidepaneStyles.ratingBoardNotifications}>
                {refills.map((notification, index) => (
                    <div
                        key={index}
                        className={slidepaneStyles.notification}
                    >
                        <h3>"Депозит успешно обработан !"</h3>
                        <p>На ваш счет поступило {formatNumber(notification.amount || 0)} {getCurrencySymbol(user.user?.profile.currency || '')}</p>
                    </div>
                ))}
            </div>
        </SlidingPane >
    );
};
