// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historyTransactions_payments-block__0Rmsf {
  background-color: #2B2930;
  border-radius: 10px;
  width: 95%;
}

.historyTransactions_title__NTTjk {
  color: white;
  font-family: GetVoIP Grotesque, sans-serif;
  margin: 2% 5%;
}

.historyTransactions_payments-header__bLjLw {
  color: #FEF7FF;
  font-weight: 400;
  padding: 2% 3%;
  border-bottom: 1px solid #333333;
}

.historyTransactions_paymentsTables__\\+ykLM {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  margin: 1% 5%;
}

.historyTransactions_payments-table-item__KAo5u {
  display: flex;
  color: #FEF7FF;
  padding: 2% 3%;
  border-radius: 5px;
  background-color: #363539;
  margin: 3px 5px;
}
.historyTransactions_payments-table-item-property__9apvI {
  width: 33%;
}
.historyTransactions_payments-table-item-property__9apvI:last-child {
  text-align: end;
}

@media (max-width: 834px) {
  .historyTransactions_paymentsTables__\\+ykLM {
    display: block;
  }
  .historyTransactions_paymentsTables__\\+ykLM:last-child {
    margin-top: 2%;
  }
  .historyTransactions_payments-header__bLjLw {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/components/HistoryTransaction/historyTransactions.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,0CAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;EACA,cAAA;EACA,gCAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,OAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,cAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACJ;AAEI;EACI,UAAA;AAAR;AAEQ;EACI,eAAA;AAAZ;;AAOA;EACI;IACI,cAAA;EAJN;EAMM;IACI,cAAA;EAJV;EAQE;IACI,kBAAA;EANN;AACF","sourcesContent":[".payments-block {\n    background-color: #2B2930;\n    border-radius: 10px;\n    width: 95%;\n}\n\n.title {\n    color: white;\n    font-family: GetVoIP Grotesque, sans-serif;\n    margin: 2% 5%;\n}\n\n.payments-header {\n    color: #FEF7FF;\n    font-weight: 400;\n    padding: 2% 3%;\n    border-bottom: 1px solid #333333;\n}\n\n.paymentsTables {\n    display: flex;\n    justify-content: space-between;\n    gap: 2%;\n    margin: 1% 5%;\n}\n\n.payments-table-item {\n    display: flex;\n    color: #FEF7FF;\n    padding: 2% 3%;\n    border-radius: 5px;\n    background-color: #363539;\n    margin: 3px 5px;\n\n\n    &-property {\n        width: 33%;\n\n        &:last-child {\n            text-align: end;\n        }\n    }\n}\n\n\n\n@media (max-width: 834px) {\n    .paymentsTables {\n        display: block;\n\n        &:last-child {\n            margin-top: 2%;\n        }\n    }\n\n    .payments-header {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-block": `historyTransactions_payments-block__0Rmsf`,
	"title": `historyTransactions_title__NTTjk`,
	"payments-header": `historyTransactions_payments-header__bLjLw`,
	"paymentsTables": `historyTransactions_paymentsTables__+ykLM`,
	"payments-table-item": `historyTransactions_payments-table-item__KAo5u`,
	"payments-table-item-property": `historyTransactions_payments-table-item-property__9apvI`
};
export default ___CSS_LOADER_EXPORT___;
