import { HeaderForTrade } from "./HeaderForTrade/HeaderForTrade.jsx";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { Diagram } from "./Diagram/Diagram";
import { DownOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';

export const TradeList = () => {
    const { Option } = Select;
    const [specs, setSpecs] = useState(
        {
            hideSideToolbar: true,
            interval: 1,
            style: "1"
        });
    const [amount, setAmount] = useState(10000);
    const [date, setDate] = useState("00:00:00");
    const [selectedTicker, setSelectedTicker] = useState("BTCUSDT");
    const [balance, setBalance] = useState({
        balance: 0,
        demo_balance: 0
    });
    const [tickers, setTickers] = useState([]);

    const [donates, setDonates] = useState([
        {
            "id": 0,
            "amount": 0,
            "is_up": true,
            "time_of_open": "2024-12-07T23:16:47.697Z",
            "dot_of_open": 0,
            "dof_of_close": 0,
            "symbol": "string",
            "prediction_result": 0,
            "sum_of_win": 0,
            "user_id": 0
        }
    ]);
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        handleGetBalance();
        handleGetHistory();
        axios
            .get("https://oneoption.ru/api/v1/options/tickers", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setTickers(res.data);
                setSelectedTicker(formatPair(res.data[0]) || null);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const formatPair = (pair: any) => {
        if (pair && typeof pair === 'string') {
            let m = pair.match(/^([A-Za-z]+)(USDT)$/i);
            if (m) {
                const formattedPair = `${m[1]} - ${m[2]}`;
                return formattedPair.toUpperCase();
            }
        }
        return pair;
    }


    useEffect(() => {
        if (selectedTicker) {
            setSpecs((prevSpecs) => ({
                ...prevSpecs,
                symbol: selectedTicker.split(" ")[0] + "USDT",
            }))
        }
    }, [selectedTicker]);

    const handleOrder = async (action: string) => {
        if (amount > 0) {
            try {
                const response = await axios.post(
                    "https://oneoption.ru/api/v1/trading/transactions/order",
                    {
                        ticker: "btcusdt",
                        action: action,
                        amount: amount,
                        time: Date.now(),
                        is_demo: true,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                console.log("Order successful:", response.data);
            } catch (error) {
                console.error("Error creating order:", error);
            }
        } else {
            console.error("Amount must be greater than 0");
        }
    };

    const handleOrderCheck = (id: string) => {
        axios.get(`https://oneoption.ru/api/v1/trading/transactions/order/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err)
        })
    }

    const handleGetBalance = () => {
        axios.get("https://oneoption.ru/api/v1/payments/balance", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(res => {
                setBalance(res.data);
                console.log(res)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleGetHistory = () => {
        axios.get("https://oneoption.ru/api/v1/trading/transactions/user/predictions", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then(res => {
            setDonates(res.data);
            res.data.foreach((item: any) => {
                handleOrderCheck(item.id);
            })
        }).catch(err => {
            console.log(err)
        })
    };

    const handleTools = () => {
        setSpecs((prevSpecs) => ({
            ...prevSpecs,
            hideSideToolbar: !prevSpecs.hideSideToolbar,
        }));
        console.log("Updated specs:", specs);
    };

    const items = [
        {
            label: "1 s",
            key: '1',
        },
        {
            label: "5 s",
            key: '5',
        },
        {
            label: "15 s",
            key: '15',
        },
    ];

    const handleDropdownChange = (key: string) => {
        setSpecs((prevSpecs) => ({
            ...prevSpecs,
            interval: parseInt(key, 10),
        }));
        console.log("Interval set to:", key);
    };

    const candles = [
        {
            label: (
                <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0H9.6V2.4H8V9.6H9.6V12H12V9.6H13.6V2.4H12V0Z" fill="#FEF7FF" />
                        <path d="M4 1.6H1.6V4.8H0V12H1.6V15.2H4V12H5.6V4.8H4V1.6Z" fill="#FEF7FF" />
                    </svg>
                    <span>Свечи</span>
                </span>
            ),
            key: "1"
        },
        {
            label: (
                <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.85714L7 5.28571L10 11L15.25 1L19 6.71429" stroke="white" />
                    </svg>
                    <span>Линия</span>
                </span>
            ),
            key: "2"
        }
    ];


    const handleCandlesChange = (key: string) => {
        setSpecs((prevSpecs) => ({
            ...prevSpecs,
            style: key,
        }))
    }

    return <div className={styles.tradeListContainer}>
        <HeaderForTrade selectedTicker={selectedTicker} setSelectedTicker={setSelectedTicker} />
        <div className={styles.tradeList}>
            <div className={styles.leftSide}>
                <div className={styles.top}>
                    <div className={styles.topLeftSide}>
                        <div>
                            <Select
                                value={specs.interval.toString()}
                                onChange={handleDropdownChange}
                                className={`${styles.time} custom-select`}
                                showArrow={false}
                                dropdownStyle={{
                                    backgroundColor: '#36343B',
                                }}
                            >
                                {items.map(item => (
                                    <Option
                                        key={item.key}
                                        value={item.key}
                                        style={{
                                            backgroundColor: '#36343B',
                                            color: 'white',
                                        }}
                                    >
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>

                        </div>
                        <div className={styles.tools}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.11106 7.82082C6.67892 7.73191 5.1381 7.3438 3.33844 6.47031L3.9791 5.21529C5.62992 6.01654 6.98506 6.34618 8.2024 6.42175C9.42584 6.4977 10.5492 6.32003 11.7582 6.05596C12.0974 5.98188 12.4427 5.9009 12.7971 5.8178C14.9681 5.30873 17.4791 4.71995 21 5.14721L20.8225 6.53838C17.5759 6.14442 15.3153 6.67224 13.1552 7.1766C12.7928 7.2612 12.4333 7.34514 12.0724 7.42396C10.8168 7.69822 9.53711 7.90934 8.11106 7.82082ZM17.9679 8.69554L20.9443 11.5848L17.9679 14.4741L16.9536 13.4806L18.1844 12.2858H3.65877V10.8839H18.1844L16.9536 9.68905L17.9679 8.69554ZM7.97503 15.4205C6.78163 15.9966 5.51781 17.3201 4.31754 20L3 19.4389C4.26436 16.616 5.71164 14.9488 7.33717 14.1641C8.98517 13.3686 10.6675 13.5502 12.2205 14.0954C13.6838 14.6093 15.118 15.4774 16.3997 16.2532C16.4615 16.2907 16.523 16.3279 16.5841 16.3648C17.9657 17.2002 19.1163 17.87 20.0806 18.078L19.7701 19.4468C18.5162 19.1764 17.1406 18.3508 15.8265 17.5563C15.781 17.5288 15.7356 17.5013 15.6902 17.4738C14.368 16.674 13.0501 15.8768 11.7334 15.4144C10.3904 14.9429 9.14596 14.8553 7.97503 15.4205Z" fill="#FEF7FF" />
                            </svg>
                        </div>
                        <div>
                            <Select
                                showArrow={false}

                                value={specs.style.toString()}
                                onChange={handleCandlesChange}
                                className={`${styles.candles} custom-select`}
                                dropdownStyle={{ backgroundColor: '#36343B' }}
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                }}
                                dropdownRender={menu => (
                                    <div style={{ backgroundColor: '#36343B', color: 'white' }}>{menu}</div>
                                )}
                            >
                                {candles.map(candle => (
                                    <Option
                                        key={candle.key}
                                        value={candle.key}
                                        className={styles.option}
                                        style={{
                                            backgroundColor: '#36343B',
                                            color: 'white',
                                        }}
                                    >
                                        {candle.label}
                                    </Option>
                                ))}
                            </Select>

                        </div>
                        <div className={styles.pen} onClick={() => handleTools()}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.5209 3.5L22 12.7766L11.0366 21.5H3V12.2699L13.5209 3.5ZM4.81475 15.1131V18.9284L5.64428 19.7352H8.7754L4.81475 15.1131ZM10.8341 19.3809L10.186 18.6245L18.7748 11.9045L19.3876 12.575L10.8341 19.3809ZM17.5677 10.5838L16.9553 9.91382L8.37618 16.5125L9.02404 17.2685L17.5677 10.5838ZM7.21403 15.1563L15.7476 8.59258L14.9478 7.71754L6.55224 14.384L7.21403 15.1563ZM5.39041 13.0281L13.7411 6.39732L13.3487 5.968L5.17731 12.7794L5.39041 13.0281Z" fill="#FEF7FF" />
                            </svg>
                        </div>
                    </div>
                    <div className={styles.topRightSide}>
                        <div className={styles.candlesChanger}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.01186 3.16467H4.7648V7.49408H2.6001V17.2353H4.7648V21.5647H8.01186V17.2353H10.1766V7.49408H8.01186V3.16467Z" fill="#00C314" />
                                <path d="M18.8356 1H15.5885V4.24706H13.4238V13.9882H15.5885V17.2353H18.8356V13.9882H21.0003V4.24706H18.8356V1Z" fill="#DE1C1C" />
                            </svg>
                        </div>
                        <div className={styles.colorChanger}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="26" height="26" rx="5" fill="#211F26" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={styles.diagram}>
                    <Diagram specs={{ hideSideToolbar: specs.hideSideToolbar, interval: specs.interval.toString(), style: specs.style, symbol: selectedTicker }} />
                </div>
            </div>
            <div className={styles.rightSide}>
                <div className={styles.rightSideHeader}>
                    <div>
                        <p className={styles.isDemo}>Демо-баланс</p>
                        <p className={styles.allAmount}>₽ {balance && balance.demo_balance}</p>
                    </div>
                    <div className={styles.level}>
                        <p className={styles.levelIndex}>1</p>
                        <p className={styles.levelText}>LVL</p>
                    </div>
                </div>
                <Select
                    value={selectedTicker}
                    onChange={(value) => setSelectedTicker(formatPair(value))}
                    dropdownStyle={{
                        backgroundColor: '#36343B',
                    }}
                    className={styles.tickers}
                >
                    {tickers.map((ticker) => (
                        <Select.Option
                            key={ticker}
                            value={ticker}
                            style={{
                                backgroundColor: '#36343B',
                                color: 'white',
                            }}
                        >
                            {formatPair(ticker)}
                        </Select.Option>
                    ))}
                </Select>
                <div className={styles.rightSideInputs}>
                    <div className={styles.rightSidetime}>
                        <label className={styles.rightSidetimeLabel}>Время</label>
                        <p>{date}</p>
                    </div>
                    <div className={styles.amount}>
                        <button className={styles.amountButton} onClick={() => setAmount(amount - 1000)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" transform="rotate(180 12 12)" fill="#FEF7FF" />
                                <path d="M4 14L4 10L20 10L20 14L4 14Z" fill="#793AFF" />
                            </svg>
                        </button>
                        <label className={styles.rightSideAmountLabel}>Сумма</label>
                        <p>{amount}</p>
                        <button className={styles.amountButton} onClick={() => setAmount(amount + 1000)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" transform="rotate(180 12 12)" fill="#FEF7FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 19L14 19L14 14L19 14L19 10L14 10L14 5L10 5L10 10L5 10L5 14L10 14L10 19Z" fill="#793AFF" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={styles.crypto}>
                    <label>{selectedTicker}</label>
                    <p className={styles.cryptoValue}>Выплата</p>
                    <p className={styles.cryptoPercent}>70%</p>
                    <p className={styles.cryptoAmount}>+35 000,00</p>
                </div>
                <div className={styles.buttons}>
                    <button className={styles.up} onClick={() => handleOrder("buy")}>
                        <p>Выше</p>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3337 26.6667L17.3337 10.44L24.787 17.8933L26.667 16L16.0003 5.33334L5.33366 16L7.21366 17.88L14.667 10.44L14.667 26.6667L17.3337 26.6667Z" fill="#FEF7FF" stroke="#FEF7FF" strokeWidth="2" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <button className={styles.down} onClick={() => handleOrder("sell")}>
                        <p>Ниже</p>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6663 5.33334L14.6663 21.56L7.21301 14.1067L5.33301 16L15.9997 26.6667L26.6663 16L24.7863 14.12L17.333 21.56L17.333 5.33334L14.6663 5.33334Z" fill="#FEF7FF" stroke="#FEF7FF" strokeWidth="2" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className={styles.historyBoard}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    <h5>Пополнения</h5>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={5} style={{ height: "2px" }}></td>
                            </tr>
                            {
                                donates.map(elm => {
                                    return <tr key={elm.id} className={styles.item}>
                                        <td>
                                            <p>{elm.symbol}</p>
                                            <p style={elm.is_up ? { color: "#00C314" } : { color: "#FF0000" }}>{elm.is_up ? "+" : "-"} {elm.symbol}</p>
                                        </td>
                                        <td>
                                            <div className={styles.fakeButton} style={elm.is_up ? { backgroundColor: "#00C314" } : { backgroundColor: "#FF0000" }}>{elm.is_up ? "выше" : "ниже"}</div>
                                            <p>₽ {elm.amount}</p>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
};
